import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button,  Tab, Tabs } from "react-bootstrap";
import { clientPath, serverPath } from "../../config/key";
import "./trueFaceUp.css";
import DiscoverWeight from "./DiscoverWeight";
import DiscoverTrueFaceUp from "./DiscoverTrueFaceUp";
import TrueFaceUpLayout from "../layout/trueFaceupLayout";
import VerticalDropdown from "../verticalDropdown/verticalDropdown";
import {
  CreditsIcons,
  ExportIcon,
  HistoryIcons,
} from "../verticalDropdown/svgData";
import CopyToClipboard from "react-copy-to-clipboard";
import { rarityInstance, withAuth } from "../../config/axios";
import Loader from "../loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import { UAParser } from "ua-parser-js";
import FcrfContext from "../../Context";
// import Loader from "../loader/loader";

const TrueFaceUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const resultData = location?.state?.data;
  const [activeTab, setActiveTab] = useState(
    resultData?.type === "discoverWeight" ? "tab2" : "tab1"
  );
  const getContext = useContext(FcrfContext);
  const {
    setUserCredits,
    userCredits,
  } = getContext;
  const [weightResult, setWeightResult] = useState({});
  const [trueFaceUpResult, setTrueFaceUpResult] = useState({});
  const [loader, setLoader] = useState(false);
  const [creditLoader, setCreditLoader] = useState(true);
  const [tokenLoader, setTokenLoader] = useState(true);
  const [loadingType , setLoadingType] = useState("progress");
  const [copied, setCopied] = useState(false);
  const [urlValue, setUrlValue] = useState(null);
  const [urlValue2, setUrlValue2] = useState(null);
  const [weightInput, setWeightInput] = useState({
    shape: "",
    length: "",
    weight: "",
  });

  useEffect(() => {
    if (resultData?.type === "discoverWeight") {
      setWeightResult({ ...resultData?.report, Id: resultData?._id  , postId: resultData?.postId });
      setWeightInput({
        shape: resultData?.payload?.shape || "",
        length: resultData?.payload?.length || "",
        weight: resultData?.payload?.width || "",
      });
      setUrlValue2(`${serverPath}/share-faceup-report/${resultData?._id}`);
    } else if (resultData?.type === "discoverTrueFaceUp") {
      setTrueFaceUpResult({
        ...resultData?.report,
        ...resultData?.payload,
        Id: resultData?._id,
        postId: resultData?.postId,
        report_number: resultData?.payload?.giaNumber

      });
      setUrlValue(`${serverPath}/share-faceup-report/${resultData?._id}`);
    }
  
    return () => {
      if (location?.state?.data) {
        navigate('/true-face-up' , { replace: true , state: { data: null } });
      }
    }
    // eslint-disable-next-line
  }, [location, resultData]);


  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const initialShape = [
    {
      shape: "Cushion",
      label: "Cushion",
    },
    {
      shape: "Radiant",
      label: "Radiant",
    },
    {
      shape: "Pear",
      label: "Pear",
    },
    {
      shape: "Oval",
      label: "Oval",
    },
    {
      shape: "Emerald",
      label: "Emerald",
    },
    {
      shape: "Heart",
      label: "Heart",
    },
    {
      shape: "Round",
      label: "Round",
    },
    {
      shape: "Marquise",
      label: "Marquise",
    },
  ];
  const [discoverShape, setDiscoverShape] = useState(initialShape);
  const [mobileInfo, setMobileInfo] = useState(true);
  useEffect(() => {
    if (tokenLoader) {
      const interval = setInterval(() => {
        window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);

      const handleMessage = (event) => {
        // console.log("event.origin", event.origin);
        // console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
        if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
          const { token } = event?.data;

          // console.log("token come form wp diary==>", token);
          // console.log("event?.data==>", event?.data);

          if (token) {
            localStorage.setItem("fcrf@token", token);
            setTokenLoader(false);
          } else {
            localStorage.removeItem("fcrf@token");
          }
        } else {
          if (!localStorage.getItem("fcrf@token")) {
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }

// eslint-disable-next-line
  }, [tokenLoader , location]);

  useEffect(()=>{
    getUserInfo();
    // eslint-disable-next-line
  },[location, weightResult, trueFaceUpResult ])

  const getUserInfo = async () => {
    setCreditLoader(true);
    const response = await withAuth().get("users/getuserinfo");
    setUserCredits({
      rarityCredits: response.data?.user?.rarityCredits || '-',
      diaryCredits: response.data?.user?.diaryCredits || '-',
      faceupCredits: response.data?.user?.trueFaceupCredit || '-',
    });
    setCreditLoader(false);
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setMobileInfo(true);
        setDiscoverShape([
          {
            shape: "Cushion",
            label: "Cu",
          },
          {
            shape: "Radiant",
            label: "Rad",
          },
          {
            shape: "Pear",
            label: "Ps",
          },
          {
            shape: "Oval",
            label: "Ov",
          },
          {
            shape: "Emerald",
            label: "Em",
          },
          {
            shape: "Heart",
            label: "Hs",
          },
          {
            shape: "Round",
            label: "Br",
          },
          {
            shape: "Marquise",
            label: "Mq",
          },
        ]);
      } else {
        setDiscoverShape(initialShape);
        setMobileInfo(true);
      }
    };

    // Initial check on component mount
    handleResize();

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);
  const dropdownItems = [
    {
      icon: HistoryIcons,
      route: "/true-face-up-history",
      label: "Search History",
    },
    {
      icon: CreditsIcons,
      route: "",
      label: `Credits: ${creditLoader ? 'credit-loading' : userCredits.faceupCredits === "-1" ? "Unlimited" : userCredits.faceupCredits}`,
    },
  ];

  const handleCopy = async () => {
    if (activeTab === "tab1") {

      setUrlValue(`${serverPath}/share-faceup-report/${trueFaceUpResult?.Id}`);
    } else {
      setUrlValue2(`${serverPath}/share-faceup-report/${weightResult?.Id}`);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 300);
    try {
            const ua = navigator.userAgent;
            const [ipResponse, uaResult] = await Promise.all([
              fetch("https://api.ipify.org?format=json"),
              new Promise((resolve) => resolve(new UAParser(ua).getResult())),
            ]);
      
            const { ip } = await ipResponse.json();

            const services = {
              IP: ip,
              "Operating System": uaResult.os.name,
              Browser: uaResult.browser.name,
              Device: uaResult.device.model || "Unknown",
            };
            await rarityInstance().post("truefaceup/copy-share-link/TrueFaceUp", {
            reportId: activeTab === "tab1" ? trueFaceUpResult?.Id : weightResult?.Id,
            postId : activeTab === "tab1" ? trueFaceUpResult?.postId : weightResult?.postId,
            faceupType : activeTab === "tab1" ? 'Discover True Face Up' : 'Discover Weight',
            shareLink: activeTab === "tab1" ? urlValue : urlValue2,
            services,
            });
    } catch (error) {
      console.error("error in copy link===", error);
    }
  };

  return (
    <>
      {loader && <Loader type={loadingType} from={"trueFaceUp"} handleClose={() => setLoader(false)}/>}
   
      <div className="true-face-up-access">
        <Helmet>
          <meta charSet="utf-8" />
          <title>True Face Up | FCRF</title>
          <link rel="canonical" href="https://fcrf.org/true-face-up" />
        </Helmet>
        <TrueFaceUpLayout header={"d-none"}>
          <div className="rarity-access-page rarity-main-page">
            <div className="container true-face-up-container">
              <div className="diary-input-content true-face-up-main ">
                <div
                  className={`true-face-up-title-container ${
                    (Object.keys(trueFaceUpResult).length > 0 &&
                      activeTab === "tab1"  && urlValue) ||
                    (Object.keys(weightResult).length > 0 &&
                      activeTab === "tab2" && urlValue2) 
                      ? "true-face-up-title-container-mobile"
                      : ""
                  }`}
                >
                  <div className="true-face-up-content">
                    <h4 className="rarity-top-heading true-heading-mob">True Face-Up</h4>
                    {/* <p>Calibrate the diamond’s value </p> */}
                  </div>
                  <div className="rarity-tool true-faceup-tool">
                    <div className="rarity-header-section grade-desktop-view">
                      <div className="rarity-header-right rarity-header-right-updated faceup-right-header position-relative d-flex align-items-center">
                        <div className="report-action-btn">
                          {((Object.keys(trueFaceUpResult).length > 0 &&
                            activeTab === "tab1" && urlValue) ||
                            (Object.keys(weightResult).length > 0 &&
                              activeTab === "tab2" && urlValue2) 
                             ) && (
                            <CopyToClipboard
                              text={activeTab === "tab1" ? urlValue : urlValue2}
                              onCopy={(e) => handleCopy(e)}
                            >
                              <Button className="outline-btn">
                                <ExportIcon />
                              </Button>
                            </CopyToClipboard>
                          )}
                        </div>
                        <span
                          className={`copied-tooltip faceup-tooltip ${
                            copied ? "show" : ""
                          }`}
                        >
                          Copied
                        </span>

                        <VerticalDropdown dropdownItems={dropdownItems} />
                      </div>
                    </div>
                    <div className="rarity-header-section grade-mob-view">
                      <div className="rarity-header-right faceup-right-header">
                        <div className="report-action-btn">
                          <div className="copy-btn-wrapper">
                            {((Object.keys(trueFaceUpResult).length > 0 &&
                              activeTab === "tab1"  && urlValue ) ||
                              (Object.keys(weightResult).length > 0 &&
                                activeTab === "tab2" && urlValue2) ) && (
                              <CopyToClipboard
                                text={activeTab === "tab1" ? urlValue : urlValue2}
                                onCopy={(e) => handleCopy(e)}
                              >
                                <Button className="outline-btn">
                                  <ExportIcon />
                                </Button>
                              </CopyToClipboard>
                            )}

                            <span
                              className={`copied-tooltip ${
                                copied ? "show" : ""
                              }`}
                            >
                              Copied
                            </span>
                          </div>
                        </div>
                        <VerticalDropdown dropdownItems={dropdownItems} />
                      </div>
                    </div>
                  </div>
                </div>
                {!mobileInfo && (
                  <div className="diary-input-desc w-100 mobile-info-box ">
                    <div>
                      <span>Info</span>
                    </div>
                    <div onClick={() => setMobileInfo(true)}>
                      <DownArrow />
                    </div>
                  </div>
                )}

                { (
                  <div  className={`diary-input-desc w-100 faceup-input-desc ${
                    mobileInfo ? "slide-in" : "slide-out"
                  }`}>
                    <div className="true-face-up-desc-first-box">
                      <p className="">
                        {activeTab==="tab1"? "Discover the visual presence of your color diamond ":"Insert dimension to discover weight "} 
                         {/* <span className="" onClick={() => setMobileInfo(false)}>
                           Got it
                        </span> */}
                      </p>
                    </div>
                  </div>
                )}

                <div className="diary-input-desc diary-input-desc-pd1  w-100 new-rarity-desc">
                  <div
                    className={`rarity-tab new-rarity-tab true-face-up-tab  `}
                  >
                    <Tabs
                      defaultActiveKey={activeTab}
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey="tab1"
                        title="Discover True Face Up"
                        className="rarity-tab1"
                      >
                        <DiscoverTrueFaceUp
                          trueFaceUpResult={trueFaceUpResult}
                          setTrueFaceUpResult={setTrueFaceUpResult}
                          setUrlValue={setUrlValue}
                          setLoader={setLoader}
                          setLoadingType={setLoadingType}
                          tokenLoader={tokenLoader}
                        />
                      </Tab>
                      <Tab eventKey="tab2" title="Discover Weight">
                        <DiscoverWeight
                          discoverShape={discoverShape}
                          mobileInfo={mobileInfo}
                          setWeightResult={setWeightResult}
                          weightResult={weightResult}
                          setUrlValue={setUrlValue2}
                          weightInput={weightInput}
                          setWeightInput={setWeightInput}
                          setLoader={setLoader}
                          setLoadingType={setLoadingType}
                          tokenLoader={tokenLoader}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="container true-face-up-container newtrue-face-up-container true-face-image-container">
              <img
                src={`${clientPath}/trueFaceUp/${
                  activeTab === "tab1"
                    ? "discover-truefaceup.png"
                    : "discover-weight.png"
                }`}
                alt="True Face Up"
                className="true-face-up-img w-100 p-1"
              />
            </div>
          </div>
        </TrueFaceUpLayout>
      </div>
    </>
  );
};

export default TrueFaceUp;


const DownArrow = () => {
  return (
    <svg
      width="17"
      height="8"
      viewBox="0 0 17 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 8.00444L0.276195 0.500672L16.7307 0.508207L8.5 8.00444Z"
        fill="#181F37"
      />
    </svg>
  );
};
