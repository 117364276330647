import { useState, useEffect, useContext } from "react";
import Layout from "../layout/layout";
import ProgressBar from "react-bootstrap/ProgressBar";
import {  Button, Tabs, Tab } from "react-bootstrap";
import { withAuth } from "../../config/axios";
import "./rarity.css";
import { useNavigate, useLocation } from "react-router-dom";
import FcrfContext from "../../Context";
import Loader from "../loader/loader";
import { rarityHandlePayload } from "../../utils/rarityStepPayload";
import { Helmet } from "react-helmet";
import GiaNumber from "./giaNumber";
import DiaryNotice from "../Models/diaryNotice";
import VerticalDropdown from "../verticalDropdown/verticalDropdown";
import {
  SaveIcons,
  HistoryIcons,
  SettingsIcons,
  CreditIcon,
} from "../verticalDropdown/svgData";
import RarityArgyle from "../rarityArgyle/rarityArgylePage";
import { getFormattedIsoDate } from "../../utils/dateUtils";
import { wpPath } from "../../config/key";
const RarityAccess = () => {
  const getContext = useContext(FcrfContext);
  const {
    steps,
    formProgress,
    setIsWp,
    inputError,
    setInputError,
    stepScroll,
    setStepScroll,
    backScroll,
    setBackScroll,
    setIsActiveVerify,
    setUserCredits,
    userCredits,

    setDateSetting,
  } = getContext;
  const setContext = useContext(FcrfContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { from, data, ischeck, type  } = location?.state || {};
  const initialState = {
    weight: "",
    carat: "",
    color: "",
    saturation: "",
    shape: "",
    clarity: "",
    select: "",
    giaNumber: "",
  };
  const [payload, setPayload] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [creditLoader, setCreditLoader] = useState(true);
  const [argyleLoader , setArgyleLoader] = useState(true);
  const [loaderType, setLoaderType] = useState("loginalert");
  const [isActive, setIsActive] = useState(false);
  const [giainputError, setGiaInputError] = useState(true);
  const [giainputTextError, setGiainputTextError] = useState(false);
  const [isCheckShapecode, setCheckShapecode] = useState(false);
  const [activeGia, setActiveGia] = useState(location?.state?.showGia !==undefined ? location?.state?.showGia :true);
  const [showArgyleContent, setShowArgyleContent] = useState(
    location?.state?.showArgyle!==undefined ? location?.state?.showArgyle : true
  );
  const [loader, setLoader] = useState(true);


  const [accordianShow, setAccordianShow] = useState(false);

  const accordianOpen = () => {
    setAccordianShow(true);
  };
  const accordianClose = () => {
    setAccordianShow(false);
  };
  const handleOnchange = (value, name, step) => {
    if (name === "weight" && (value < 0.18 || value > 99)) {
      setInputError(true);
      const stepStr = `?Step=${step}`;
      window.history.pushState(step, null, stepStr);
      setContext.setSetps(step);
      const newPayload = rarityHandlePayload(value, name, step, payload, from);
      setPayload(newPayload);
      return;
    }
    setInputError(false);
    const stepStr = `?Step=${step}`;
    window.history.pushState(step, null, stepStr);
    setContext.setSetps(step);
    if (value) {
      const nextStep = step + 1;
      setContext.setSetps(nextStep);
    } else {
      setContext.setSetps(step);
    }
    const newPayload = rarityHandlePayload(value, name, step, payload, from);
    setPayload(newPayload);
  };

  useEffect(() => {
    if (from === "report") {
      setPayload(data);
    }
  }, [from, data]);

  const props = {
    payload,
    handleOnchange,
    inputError,
    setStepScroll,
  };

  const handleSubmit = async (inputtype) => {
    try {
      if (
        localStorage.getItem("fcrf@token") === "undefined" ||
        !localStorage.getItem("fcrf@token")
      ) {
        setLoading(true);
        return;
      }

      setLoading(true);
      setLoaderType("progress");
      const response = await withAuth().post("report/rarity", {
        ...payload,
        type: inputtype === "manual" ? "manual" : "fromgia",
      });
      const { Id, status, report, type, msg } = response.data || {};
      // console.log("response  of the report==>", response);
      if (status === 401) {
        setLoaderType(msg);
      }

      if (status === 203) {
        setLoading(false);
        navigate("/", {
          state: { from: "rarityreport", ischeck: true, type },
        });
      }

      const {
        textSheetData,
      } = report || {};
      const { H12 } = textSheetData || {};
      const isoDate = getFormattedIsoDate(H12);
      setDateSetting({
        stringDate: H12,
        isoDate: isoDate,
      });
      if (status === 200) {
        navigate("/report", {
          state: {
            id: Id,
            report: report,
            action: "Create",
            fromURL: "rarity",
            type: inputtype === "manual" ? "manual" : "fromgia",
          },
        });
      }
    } catch (e) {
      console.log("rarity error =>", e);
      if(e.response?.status === 403){
      setLoaderType(e.response?.data?.msg);
        setLoading(true);
     }else{
        setLoading(false);
        console.log("handleSubmit error", e);
      }
    }
  };

useEffect(() => {

  
    if (loader) {
      const interval = setInterval(() => {
        window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);

      const handleMessage = (event) => {
        setIsActiveVerify(true);
        // console.log("event.origin", event.origin);
        // console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
        if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
          const {
            is_free,
            token,
            is_wp,
          } = event?.data;

          setIsWp(is_wp);
          // console.log("token come form wp diary==>", token);
          // console.log("event?.data==>", event?.data);

          if (token) {
            localStorage.setItem("is_free", is_free ? is_free : false);
            localStorage.setItem("fcrf@token", token);
            setLoader(false);
          } else {
            localStorage.removeItem("fcrf@token");
          }
        } else {
          if (!localStorage.getItem("fcrf@token")) {
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loader]);

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
  }, [location]);

  const getUserInfo = async () => {
    setCreditLoader(true);
    const response = await withAuth().get("users/getuserinfo");
    setUserCredits({
      rarityCredits: response.data?.user?.rarityCredits || '-',
      diaryCredits: response.data?.user?.diaryCredits || '-',
    });
    setCreditLoader(false);
  }

  const initialWidth = window.innerWidth;
  const [screenWidth, setScreenWidth] = useState(initialWidth);

  const handleResize = (e) => {
    const currentScreenSize = e.target.innerWidth;
    setScreenWidth(currentScreenSize);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {

    if (steps === 1 && !backScroll) {
      window.scrollTo({
        top: 0,
      });
    }
        else if (steps === 3) {
      const position = document
        .getElementById("diamondShape")
        .getBoundingClientRect();
      window.scrollTo({
        top: position.top + window.scrollY - (screenWidth <= 767 ? 10 : 56),
      });
        } else if (screenWidth <= 767 && steps === 4) {
          const position = document
        .getElementById("diamondSaturation")
        .getBoundingClientRect();
      window.scrollTo({
        top: position.top + window.scrollY - (screenWidth <= 767 ? 10 : 56),
      });
    } else if (screenWidth > 767 && steps === 4) {
      const position = document.getElementById("diamondSaturation");
      position.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
    else if (steps === 5) {
      const position = document.getElementById("diamondClarity");
      position?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    } else if (steps > 5) {
      const scrollView = document.getElementById("processBtn");
      scrollView?.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      return;
    }
  }, [steps, payload, screenWidth, stepScroll, location.search, backScroll]);

  window.onpopstate = (event) => {
    if (
      location.search.includes("Step")
    ) {
      const stepStr = `?Step=1`;
      window.history.pushState(1, null, stepStr);
      setContext.setSetps(1);
      setPayload(initialState);
      setBackScroll(true);
      setTimeout(() => {
        setBackScroll(false);
      }, 250);
    }
  };
  const handleClose = () => {
    setLoading(false);
    setIsActive(false);
  };

  const [activeTab, setActiveTab] = useState(location?.state?.tab || "tab1");

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setActiveGia(true)
  setShowArgyleContent(true)

    const stepStr = `?Step=1`;
      window.history.pushState(1, null, stepStr);
    setContext.setBackScroll(false)
    setContext.setSetps(1);
    setPayload({
      type: "",
      weight: "",
      carat: "",
      color: "",
      saturation: "",
      shape: "",
      clarity: "",
      select: "",
      giaNumber: "",
    })
  };

  // Genarate rarity report  using GIA number
  const handleGIAInput = (e) => {
    const { value } = e.target || {};
    setPayload({ ...payload, giaNumber: value });
    if (value.length >= 7 && value.length <= 10) {
      setGiaInputError(false);
      setGiainputTextError(false);
    } else if (value.length > 10) {
      setGiainputTextError(true);
      setGiaInputError(true);
    } else {
      setGiainputTextError(false);
      setGiaInputError(true);
    }
  };
  useEffect(() => {
    // console.log("check render", { from, ischeck });
    if (from === "rarityreport" && ischeck) {
      setCheckShapecode(true);
      navigate("/", {
        state: { from: "", ischeck: false, type },
      });
    }
    // eslint-disable-next-line
  }, [from, ischeck, navigate, type]);

  const dropdownItems = [
    {
      icon: HistoryIcons,
      route: "/saved-report?report",
      label: "Search History",
    },
    { icon: SaveIcons, route: "/rarity-save-history", label: "Saved Reports" },
    { icon: SettingsIcons, route: `/settings`, label: "Report Settings" },
    { icon: CreditIcon,  label: `Credits: ${ creditLoader? 'credit-loading' :  userCredits.rarityCredits ==="-1" ? "Unlimited" : `${userCredits.rarityCredits}`}` },
  ];
  const dropdownArgyleItems = [
    {
      icon: HistoryIcons,
      route: "/saved-argyle-report",
      label: "Search History",
    },
    { icon: SaveIcons, route: '/argyle-save-history', label: "Saved Reports" },
    { icon: SettingsIcons, route: `/settings`, label: "Report Settings" },
    { icon: CreditIcon,  label: `Credits: ${ creditLoader? 'credit-loading' :  userCredits.rarityCredits ==="-1" ? "Unlimited" : `${userCredits.rarityCredits}`}` },
  ];


 

  return (
    <>
      <Helmet>
        <title>FCRF - Rarity Calculator</title>
      </Helmet>
    
       <Layout payload={payload} setPayload={setPayload}activeTab={activeTab}  showArgyleContent={showArgyleContent} >
          {loading && <Loader type={loaderType} handleClose={handleClose} />}
          <div className={`rarity-access-page new-rarity-access-page rarity-main-page ${steps !== 1 ? "rarity-access-page-height":""}`}>
            <div className={`rarity-progress-bar ${activeTab ==='tab1'?   activeGia  ? "bar-height-none":"":
            showArgyleContent?"bar-height-none":""}`}>
              <ProgressBar
                className={`rarity-progress w-100 overflow-x-hidden  
                ${activeTab === "tab1"
                    ? activeGia
                      ? "disable-rarity-progress"
                      : ""
                    : showArgyleContent? "disable-rarity-progress" :""
                }`}now={formProgress}
                visuallyHidden
              />
            </div>
            <div className="container">
              <div
                className="rarity-access-content  diary-input-content diary-input-mob new-rarity-access-content">
                  <div className="diary-title diary-title-mt w-100">
                  <h1 className="rarity-top-heading mob-rarity-heading">
                {activeTab === "tab1"
                ? "The Rarity Report"
              : "The Argyle Rarity Report"}
                <span className="sup-text">™</span>
                    <span
                  className={`beta-top ${
                        activeTab === "tab1"
                          ? "rarity-beta-top"
                          : "argyle-beta-top"
                      }`}
                    >
                      [BETA]
                    </span>
                  </h1>
                  <VerticalDropdown dropdownItems={activeTab==="tab1"? dropdownItems : dropdownArgyleItems} />
                </div><div
                  className={`w-100 dairy-banner dairy-banner-shadow rarity-banner  ${
                    activeTab === "tab1"
                      ? "tab1_rarity "
                      : "rarity-header-banner"
                  }`}
                  style={{
                    backgroundImage: `${
                      activeTab === "tab1"
                        ? `url(${"/rarityImage/rarity-report-hero-desktop.jpg"})`
                        : `url(${"/rarityImage/argyle-report-hero-desktop.jpg"})`
                    }`,
                  }}
                >
                  <h1>
                    {" "}
                    {activeTab === "tab1"
                      ? "The Rarity Report"
                      : "The Argyle Rarity Report "}
                  </h1>
                </div>
                <div
                  className={`w-100 dairy-banner diary-mob-banner1 new-diary-mob-banner  dairy-banner-shadow rarity-banner ${
                    activeTab === "tab1"
                      ? "rarity-banner-tab1"
                      : "rarity-banner-tab2"
                  }`}
                  style={{
                    backgroundImage: `${
                      activeTab === "tab1"
                        ? `url(${"/rarityImage/rarity-hero-mobile.jpg"})`
                        : `url(${"/rarityImage/argyle-hero-mobile.jpg"})`
                    }`,
                  }}
                >
                  <h1 className="new-rarity-heading">
                    {" "}
                    {activeTab === "tab1"
                      ? "The Rarity Report"
                      : "The Argyle Rarity Report"}{" "}
                  </h1>
                </div>
                <div className="diary-input-desc diary-input-desc-pd1  w-100 new-rarity-desc">
                  <div
                   className={`rarity-tab new-rarity-tab  
  ${activeTab === "tab1" ? "rarity-tab1" : "rarity-tab2"}  

                ${activeGia ? "" : "buyGiaActive"} 
  ${(activeTab === "tab2" && !showArgyleContent)  ? "argyle-rarity-form" : ""}`}>
                  <Tabs
                    defaultActiveKey={activeTab}
                    id="uncontrolled-tab-example"
                    onSelect={handleTabSelect}
                  >
                    <Tab eventKey="tab1" title="RARITY"
                        className="rarity-tab1">
                      <GiaNumber
                        handleSubmit={handleSubmit}
                        handleGIAInput={handleGIAInput}
                        giainputError={giainputError}
                        isActive={isActive}
                        loader={loader}
                        giainputTextError={giainputTextError}
                        loading={loading}
                        {...props}
                          steps={steps}
                          activeGia={activeGia}
                          setActiveGia={setActiveGia}
                          />
                    </Tab>
                    <Tab eventKey="tab2" title="ARGYLE DATABASE"
                        className={`${activeTab ==="tab1" ? 'argyle-db-tab': 'argyle-new-design' }`}
                      >
                       
                        {showArgyleContent ? (
                          <>
                            <div className="argyle-database">
                              <h3>ARGYLE DIAMONDS</h3>
                              <p>
                                The Argyle Rarity Report, drawing on data from
                                the Argyle archives of the annual premium
                                tenders (2004 to 2020), provides an essential
                                part of your Argyle diamond’s story. With the
                      mine's closure, this report has become an
                                invaluable resource, illustrating the rarity of
                                all Argyle-certified diamonds and granting them
                                a distinguished status within this elite group.
                                Each Argyle diamond that features in this rarity
                                report can earn a place in the 'hall of fame' of
                                diamonds sold at these tenders. Users can access
                                tailored insights to see how the rarity of their
                                Argyle diamond measures up against these
                                exceptional gems.
                              </p>
                              <Button
                                onClick={() => {
                                  setShowArgyleContent(!showArgyleContent);
                                }}
                              >
                                Argyle Diamonds
                              </Button>
                            </div>
                          </>
                        ) : (
                          activeTab ==='tab2' &&

                          <RarityArgyle
                            setShowArgyleContent={setShowArgyleContent}
                            showArgyleContent={showArgyleContent}
                      activeTab={activeTab}
                            setLoader={setArgyleLoader}
                      loader={argyleLoader}
                       />
                      )}
                    </Tab>
                  </Tabs>
                </div>
                </div>
          {activeTab === "tab1" && (
            <div className="diary-input-desc diary-input-desc-pd  w-100">
                    {!accordianShow && (
                      <div
                        onClick={accordianOpen}
                        className="accordian-down-header"
                      >
                        <ArrowSvg />
                        <p className="rarity-top-heading">
                          About The Rarity Report
                        </p>
              </div>
                    )}
                    {accordianShow && (
                <div >
                        <p>
                          The Fancy Color Diamond Rarity Report [beta] (FCDR)
                          provides data-based rarity range estimates for
                  twenty-five different colors of natural fancy color
                    diamonds. The data is based on statistics provided by
                          diamond grading labs, proprietary FCRF gemological and
                    online surveys conducted with leading industry
                          professionals. For more information about our Rarity Methodology  <a href={`${wpPath}/fundamentals-and-methodology`} target="_blank" rel="noopener noreferrer">Click here</a> 
                        </p>
                    <div className="arrow-up" onClick={accordianClose}>
                  <ArrowSvg />
                    </div>
                      </div>
                  )}
                  </div>
                )}
                </div>
              </div>
            <div className="container"></div>
          </div>

          <DiaryNotice
            show={isCheckShapecode}
            setShow={setCheckShapecode}
            giaNumber={payload?.giaNumber}
            type={type}
            reportType="rarity"
            msg="The Rarity Report only supports fancy color diamonds"
          />
        </Layout>
    </>
  );
};

export default RarityAccess;

const ArrowSvg = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 109.28 90.9"
    >
      <path d="M89.6,21.81a3.69,3.69,0,0,0-3.21-5.53l-31.76.11-31.77.11a3.69,3.69,0,0,0-3.17,5.55l16,27.45L48,70.72a7.86,7.86,0,0,0,13.61,0l12.2-21.3Z" />
    </svg>
  );
};
