import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { withAuth } from "../../config/axios";
import {
  DiaryDiamondColors,
  DiaryDiamondShapes,
} from "../../utils/dairyDiamondsDetail";
import TrueFaceupModel from "../Models/trueFaceupModel";
import { serverPath } from "../../config/key";
import { UAParser } from "ua-parser-js";
import { formatDate } from "../../utils/dateUtils";

const DiscoverTrueFaceUp = ({
  trueFaceUpResult,
  setTrueFaceUpResult,
  setUrlValue,
  setLoadingType,
  setLoader,
  tokenLoader,
}) => {
  const [viewOnce, setViewOnce] = useState(false);
  const [showError, setShowError] = useState(false);
  const [type, setType] = useState("");



  const [, setGIATextError] = useState(false);
  const [giaNumber, setGiaNumber] = useState("");
  const [giaLoading, setGiaLoading] = useState(false);
  const [imgScaleVal, setImgScaleVal] = useState(1);

  const calculateTrueFaceUp = () => {
    setTrueFaceUpResult({});
    setGiaNumber("");
    setImgScaleVal(1);
  };
  const handleGIAInput = (e) => {
    if (e.key === "Enter") {
      handleGIASubmit(e);
    }
    const { value } = e.target || {};
    setGiaNumber(value);
    setGIATextError(false);
  };
  const handleGIASubmit = async (e) => {
    e.preventDefault();
    if (!giaNumber || giaNumber.length < 7 || giaNumber.length > 10) {
      setGIATextError(true);
      return;
    }
    setGIATextError(false);
    setGiaLoading(true);
    try {
      const ua = navigator.userAgent;
      const [ipResponse, uaResult] = await Promise.all([
        fetch("https://api.ipify.org?format=json"),
        new Promise((resolve) => resolve(new UAParser(ua).getResult())),
      ]);

      const { ip } = await ipResponse.json();
      const response = await withAuth().post("truefaceup/discover-truefaceup", {
        giaNumber,
        services: {
          IP: ip,
          "Operating System": uaResult.os.name,
          Browser: uaResult.browser.name,
          Device: uaResult.device.model || "Unknown",
        },
      });
      if (response.data.status === 200) {
        setUrlValue(
          `${serverPath}/share-faceup-report/${response.data?.data?.Id}`
        );
        setTrueFaceUpResult(response.data?.data);
        const sizeValueInit =
          response.data?.data?.length / response.data?.data?.width || 0;
          // console.log("sizeValueInit", sizeValueInit);


        let sizeValue =
          sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] || 0;
          // console.log("sizeValue", sizeValue);
        if (
          response.data?.data?.shape === "Cushion" ||
          response.data?.data?.shape === "Radiant"
        ) {
          if (sizeValue <= 1.05) {
            setImgScaleVal(1);
          } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
            setImgScaleVal(1.075);
          } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
            setImgScaleVal(1.15);
          } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
            setImgScaleVal(1.225);
          } else if (sizeValue >= 1.35) {
            setImgScaleVal(1.25);
          }
        } else if (response.data?.data?.shape === "Emerald") {
          if (sizeValue <= 1.14) {
            setImgScaleVal(0.775);
          } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
            setImgScaleVal(0.85);
          } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
            setImgScaleVal(0.925);
          } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
            setImgScaleVal(1);
          } else if (sizeValue >= 1.45) {
            setImgScaleVal(1.075);
          }
        }
        setGiaNumber("");
        setGiaLoading(false);
        setViewOnce(false);
        // setImgScaleVal(1);
      } else if (response.data.status === 403) {
        setLoadingType(response?.data?.msg);
        setLoader(true);
        // setShowError(true);
        // setType("truefaceup-member");
        setGiaLoading(false);
      } else if (response.data.status === 401) {
        setShowError(true);
        setType("truefaceup-unauthorized");
        setGiaLoading(false);
      } else if (response.data.status === 203) {
        setShowError(true);
        setType(response.data?.type || response.data?.msg);
        setGiaLoading(false);
      } else {
        setShowError(true);
        setType(response.data?.type || response.data?.msg);
        setGiaLoading(false);
      }
    } catch (error) {
      setGiaLoading(false);
      console.log("error", error);
      console.log("error in discover api", error);
    }
  };
  setTimeout(() => {
    setViewOnce(true);
  }, 500);
 
  useEffect(()=>{

    if(Object.keys(trueFaceUpResult).length){
      const sizeValueInit = 
          trueFaceUpResult?.length / trueFaceUpResult?.width || 0;  

      let sizeValue =
          sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] || 0;
          
        if (
          trueFaceUpResult?.shape === "Cushion" ||
          trueFaceUpResult?.shape === "Radiant"
        ) {
          if (sizeValue <= 1.05) {
            setImgScaleVal(1);
          } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
            setImgScaleVal(1.075);
          } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
            setImgScaleVal(1.15);
          } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
            setImgScaleVal(1.225);
          } else if (sizeValue >= 1.35) {
            setImgScaleVal(1.25);
          }
        } else if (trueFaceUpResult?.shape === "Emerald") {
          if (sizeValue <= 1.14) {
            setImgScaleVal(0.775);
          } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
            setImgScaleVal(0.85);
          } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
            setImgScaleVal(0.925);
          } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
            setImgScaleVal(1);
          } else if (sizeValue >= 1.45) {
            setImgScaleVal(1.075);
          }
        }
      }


  }, [ trueFaceUpResult])

  return Object.keys(trueFaceUpResult).length ? (
    <div className="true-face-up-result-main">
      <div className="true-face-up-result">
        <div className="true-face-up-result-left">
          {trueFaceUpResult?.D29 >= 2 ? (
            <div className="visual-presence-carat">
              <p>Visual Presence</p>
              <h4 className="m-0">
                <span>
                  {(trueFaceUpResult?.C37).replace(" - ", " to ")} carat
                </span>{" "}
              </h4>
              <p>{`${trueFaceUpResult?.D29?.toFixed(2)} % Larger`}</p>
            </div>
          ) : -2 <= trueFaceUpResult?.D29 && trueFaceUpResult?.D29 <= 2 ? (
            <div className="visual-presence-carat standard-faceup">
              <p>Visual Presence</p>
              <h4 className="m-0" >
                {/* <span>{trueFaceUpResult?.carat} carat</span>{" "} */}
                True to its weight
              </h4>
            </div>
          ) : (
            <div className="visual-presence-carat">
              <p>Visual Presence</p>
              <h4 className="m-0">
                <span>
                  {(trueFaceUpResult?.C37).replace(" - ", " to ")} carat
                </span>{" "}
              </h4>
              <p>{`${trueFaceUpResult?.D29?.toFixed(2)}% Smaller`}</p>
            </div>
          )}

          <div className="true-face-up-btn">
            <button
              type="button"
              className="btn btn-outline-warning true-face-btn-new"
              onClick={calculateTrueFaceUp}
            >
              <AddIcon /> Calibrate Another
            </button>
          </div>
          <p className="faceup-report-bottom">
            Face-up ID#: {trueFaceUpResult?.postId?.replace("#","")} for GIA Report{" "}
            {trueFaceUpResult?.report_number} | {formatDate(new Date())} | DB Updated Feb 20,
            '25
          </p>
        </div>

        <div className="true-face-up-result-right">
          <div className="faceup-frame-content true-faceup-frame-content faceup-discover">
            <div
              className="faceup-frame-wrapper true-faceup-frame-wrapper"
              // ref={faceUpBoxAnimate}
            >
              <h6 className="mb-4">
                Face up result for this <span>{trueFaceUpResult?.carat} </span>{" "}
                carat
              </h6>
              <div className={"faceup-frame"}>
                <div
                  className="faceup-frame-images true-faceup-images"
                  style={{
                    transform: `translateY(${
                      imgScaleVal > 1.24 ? "0" : "0"
                    }) scaleY(${imgScaleVal})`,
                  }}
                >
                 
                 <img
                    className={`face-card-image true-face-card-image ${viewOnce ? "animate" : ""}`}
                    src={`/images/diamond-${
                      DiaryDiamondColors.find(
                        (elem) => elem?.value === trueFaceUpResult?.color
                      )?.img || "none"
                    }-${
                      DiaryDiamondShapes.find(
                        (item) => item.value === trueFaceUpResult?.shape
                      )?.img
                    }.svg`}
                    alt={`new`}
                    style={{
                      transform: `translate(${viewOnce ? "0%" : "-100%"}, ${
                        trueFaceUpResult?.shape === "Pear" ? "6%" : "0%"
                      })`,
                      // transform: `translateY(${
                      //   trueFaceUpResult?.shape === "Pear" ? "6%" : "0"
                      // })`,
                      width: `${
                        trueFaceUpResult?.profiler_value === "0" ||
                        trueFaceUpResult?.profiler_value === "-1" ||
                        trueFaceUpResult?.profiler_value === "-2" ||
                        trueFaceUpResult?.profiler_value === "-3"
                          ? "200px"
                          : trueFaceUpResult?.profiler_value === "1"
                          ? "190px"
                          : trueFaceUpResult?.profiler_value === "2"
                          ? "180px"
                          : trueFaceUpResult?.profiler_value === "3"
                          ? "170px"
                          : ""
                      }`,
                      opacity: `${viewOnce ? "1" : "0"},`,
                      position: 'relative',
                      zIndex: 999
                    }}
                  />

                  <img
                    className={`face-card-image faceup-image-first ${
                      viewOnce ? "animate" : ""
                    }`}
                    src={`/images/diamond-${
                      DiaryDiamondColors.find(
                        (elem) => elem?.value === trueFaceUpResult?.color
                      )?.img ?? "none"
                    }-${
                      DiaryDiamondShapes.find(
                        (item) => item.value === trueFaceUpResult?.shape
                      )?.img
                    }.svg`}
                    alt={`new`}
                    style={{
                      // transform: `translate(${viewOnce ? "0%" : "-100%"}, ${
                      //   trueFaceUpResult?.shape === "Pear" ? "6%" : "0%"
                      // })`,
                      zIndex:0,
                      width: `${
                        trueFaceUpResult?.profiler_value === "1" ||
                        trueFaceUpResult?.profiler_value === "2" ||
                        trueFaceUpResult?.profiler_value === "3" ||
                        trueFaceUpResult?.profiler_value === "0"
                          ? "200px"
                          : trueFaceUpResult?.profiler_value === "-1"
                          ? "190px"
                          : trueFaceUpResult?.profiler_value === "-2"
                          ? "180px"
                          : trueFaceUpResult?.profiler_value === "-3"
                          ? "170px"
                          : ""
                      }`,
                    }}
                  />



                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="diamond-weight-container diamond-faceup-container " id="diamondWeight">
      <Form onSubmit={handleGIASubmit} className="center-gia-input ">
        <div class="rarity-tab-beta true-face-up-tab1">
          <h6>TYPE GIA #</h6>
        </div>
        <Form.Group className={`diamond-weight true-face-up-gia-input`}>
          <Form.Control
            className="true-faceup-gia-input"
            type="number"
            placeholder="GIA Report Number"
            pattern="/^\d{7,10}$/"
            name="number"
            onChange={(e) => handleGIAInput(e)}
            value={giaNumber}
            //ref={inputRef}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) => e.key === "Enter" && handleGIASubmit(e)}
            autoFocus={true}
          />
          {/* <span className="gia-below-text">GIA #</span>
          {giainputError && (
            <span className="error-text">
              Please check the number you entered
            </span>
          )} */}
        </Form.Group>
        <div className="rarity-process-content rarity-gia-btn ">
          <Button
            className="processBtn newProcessBtn"
            disabled={
              giaNumber?.length < 7 || giaNumber?.length > 10 ? true : false
            }
            type="submit"
          >
            {giaLoading || tokenLoader ? (
              <Spinner animation="border" />
            ) : (
              "Calculate"
            )}
          </Button>
        </div>
      </Form>
      <TrueFaceupModel show={showError} setShow={setShowError} type={type} />
    </div>
  );
};

export default DiscoverTrueFaceUp;

const AddIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.14286H9.14286V16H6.85714V9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286Z"
        fill="#BF8F02"
      />
    </svg>
  );
};
