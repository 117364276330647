/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";

import { withoutAuth } from "../../config/axios";

import Loader from "../loader/loader";
import DiaryGIA from "./diaryGIA";
import DiarySaturation from "./diarySaturation";
import DiaryOrigin from "./diaryOrigin";
import DiaryShape from "./diaryShape";
import LuxuryHistory from "./luxuryHistory";
import DiaryFluorescence from "./diaryFluorescence";
import DiaryClarity from "./diaryClarity";
import DiaryDiamondIndex from "./diaryDiamondIndex";
import DiaryRarity from "./diaryRarity";
import DiaryColorCause from "./diaryColorCause";
import { FamousDiary } from "../../utils/famousDiary";
import FaceUpDiary from "./faceUpDiary";
import "./diary.css";
import FcrfContext from "../../Context";
import { getColorCode } from "../../utils/graphColor";

import { queryString } from "../../utils/queryString";

import { Helmet } from "react-helmet";

import { clientPath } from "../../config/key";
import { DiaryCauseShape, DiaryDiamondColors, DiaryDiamondShapes } from "../../utils/dairyDiamondsDetail";
import { useLocation, useParams } from "react-router-dom";


import { decryptString, extractShareIdByKey } from "../../utils/decryptText";
import NewDiaryWeight from "./newDiaryWeight";
import ScrollToTop from "../common/scrolltotop";
// import DiaryStoryteller from "./diaryStoryteller";
const ShareDiaryReport = () => {
 
  const getContext = useContext(FcrfContext);
  const {
    dateSetting,
  } = getContext;
  const [companyLogo, setCompanyLogo] = useState("");
  const [custom_branding, setCustomBranding] = useState("0")
  const { id } = queryString();
  const [reportData, setReportData] = useState({});
  const [reportId, setReportId] = useState({ Id: "", postId: "", raritypdfUrl: '', rairtyID: '' });
  const [loading, setLoading] = useState(true);
  const [imgScaleVal, setImgScaleVal] = useState(1);
  const [sidebarCard, setSidebarCard] = useState({});
 

  const [metaImgData, setMetaImgData] = useState({
    color:"",
    shape:""
  })
  const sharereportIds = useParams();
  const location=useLocation()
  useEffect(() => {
  const params = location.search;

  async function fetchData(shareId) {
    setSidebarCard(shareId);
    const {postId,createdBy} = await getReportById(shareId);
    if (postId) await sharePageLoaded(postId,createdBy)

  }
  if(params?.includes("?id")){
    const decData = decryptString(id);
    const shareIdvalue = extractShareIdByKey(decData, "shareId")
    fetchData({shareId: shareIdvalue});
  }
  else{
      const shareId = { shareId: sharereportIds?.id }
      fetchData( shareId);
      }
 
  }, [id]);

  const sharePageLoaded = async (postId,createdBy) => {
    try {
      await withoutAuth().post("report/share-link-open/Diary", {
        reportId: postId,
        createdBy,
        shareUrl: window.location.href
      });
    } catch (error) {
      console.error('error in open link===', error);
    }
  }

  const getReportById = async (shareId) => {
    try {
      const response = await withoutAuth().post("diamond/getsharereportbyId", {
        shareId,
      });
      const { status, getReport, showCard, companyInfo,createdBy } = response?.data;
      // console.log(response);
      const { _id, postId, report, rairtyPostID, rairtyID } = getReport || {};
      const { raritypdfUrl } = report || {}
      // console.log("report", report);

      if (status === 200) {
        setSidebarCard(showCard);
        const obj = {
          Id: _id,
          postId,
          rairtyPostID,
          rairtyID,
          raritypdfUrl,
        };
        setCompanyLogo(companyInfo?.companyLogo || "")
        setCustomBranding(companyInfo?.customBranding || "0")
        setReportId(obj);
        setReportData(report);       
        setTimeout(() => {
          setLoading(false);
        }, 3500);
        const result = DiaryDiamondColors.filter(
          (elem) => report.data?.color === elem.value
        );
        const showColorImg = result[0]?.img;
        const resultShapeCode = DiaryCauseShape.filter(
          (elem) => report?.data?.shape_code === elem.code
        );
        const showShapeImgCode = resultShapeCode[0]?.shape;
        const resultShape = DiaryDiamondShapes.filter(
          (elem) => showShapeImgCode === elem.value
        );
        const showShapeImg = resultShape[0]?.img;
        setMetaImgData({color:showColorImg , shape: showShapeImg})
      }
      return { postId,createdBy }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const {
    data,
    diaryInfo,
    closestDiamondValues,
    closestDiamondDates,
    gia_report,
    range_slider,
    extraritypdfUrl
  } = reportData || {};
  const {
    originInfo,
    shapInfo,
    profilerInfo,
    florescenceInfo,
    weightInfo,
    saturationInfo,
    clarityInfo,
    rarityInfo,
    colorInfo,
    famousDiamondInfo,
    appreciation
  } = diaryInfo || {};
  let textColor = "#333";
  const color = getColorCode(data?.color);
  const famousView =
    FamousDiary.filter(
      (elem) => gia_report?.results?.color_grade === elem.color
    ) || [];
  const sizeValueInit =
    gia_report?.results?.data?.measurements?.length /
    gia_report?.results?.data?.measurements?.width || 0;
  const sizeValue =
    sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] || 0;
  useEffect(() => {
    if (
      range_slider?.shapeName === "Cushion" ||
      range_slider?.shapeName === "Radiant"
    ) {
      if (sizeValue <= 1.05) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
        setImgScaleVal(1.075);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(1.15);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(1.225);
      } else if (sizeValue >= 1.35) {
        setImgScaleVal(1.3);
      }
    } else if (range_slider?.shapeName === "Emerald") {
      if (sizeValue <= 1.14) {
        setImgScaleVal(0.775);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(0.85);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(0.925);
      } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.45) {
        setImgScaleVal(1.075);
      }
    }
  }, [sizeValue]);


  const goToRairtyReport = async () => {
    window.open(extraritypdfUrl);
    const { rairtyPostID, rairtyID: Id } = reportId || {};
    const rairtyID = rairtyPostID?.replace("#", "");
    let obj = {
      Id,
      postId: rairtyPostID
    }
   
    const shareLink = `${clientPath}/share-rarity-report/${obj.Id}`;
   const updatedData =  await withoutAuth().post(
      "report/updateReportStatusbyId/", {
      rairtyID,
      stringDate: dateSetting?.stringDate,
      shareLink
    }
    );
    const pdfUrl = updatedData?.data?.pdfUrl
    await rarityWebhook(pdfUrl);
   
  };

  const rarityWebhook = async (pdfUrl) => {
    try {
      const { rairtyPostID, postId } = reportId || {};
      await withoutAuth().post("report/rarity-from-share", {
        rarityReportId: rairtyPostID,
        reportId: postId,
        raritypdfUrl:pdfUrl,
      });
      return true;
    } catch (error) {
      console.log("error in rarity-from-diary api", error);
    }
  }
  const formatDateReport = (input) => {
    const dateObject = new Date(input);
    if (isNaN(dateObject.getTime())) {
      return "Invalid Date";
    }
    const day = dateObject.getDate();
    const month = dateObject.getMonth();
    const year = dateObject.getFullYear();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${months[month]} ${day}, ${year}`
  };
  const formatDate = (inputDate) => {
    const dateComponents = inputDate?.match(
      /(\w+) (\d+)(?:st|nd|rd|th), (\d+)/
    );
    if (!dateComponents) {
      return "Invalid Date";
    }
    const months = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };
    const mons = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[dateComponents[1]];
    const day = dateComponents[2];
    const year = dateComponents[3];
    return `${mons[month]} ${day}, ${year}`;
  };

  return (
    <>
      <Helmet>
        <title>FCRF - Diary Report</title>
        <meta name="description" content="Shared Diary Report" />
        <meta property="og:title" content="Diamond Diary"/>
        <meta property="og:description" content="your Diary Report"/>
        <meta property="og:image" content={`${clientPath}/images/diamond-${metaImgData.color}-${metaImgData.shape}.svg`} />
      </Helmet>
      {loading && <Loader type="progress" />}
      <div className="diary-page">
        <div className="container">
          <div className="diary-wrapper">
            <div className="report-logo">
              <img
              onClick={()=>{
                window.location.href=process.env.REACT_APP_WP_BASE_URL
              }}
               
                src={`${clientPath}/icons/FCRF_logo.svg`}
                alt="FCRF logo"
              />
            </div>
            <div className="diary-title">
            <h1 className="report-page-hedding mt-2">
                <span className="beta-top-report-page">[BETA]</span>
                  Diamond diary Report<span className="sup-text">™</span> 
                </h1>
            </div>
            {
                  companyLogo  && custom_branding==="1"&& <div>
                  <p className="rarity-tool-text m-0">Issued for:</p>
                  <div className="company_logo">
            <img
            className="imgstyle"
              src={companyLogo}
              alt="comp_logo"
              
            />
          </div>
                 
                </div>
                }
            <DiaryGIA
              reportData={reportData}
              florescenceInfo={florescenceInfo}
              imgScaleVal={imgScaleVal}
              range_slider={range_slider}
              sidebarCard={sidebarCard}
              isHide={true}
            />
          

            {originInfo?.C46 !== "#N/A" && sidebarCard?.originBox && (
              <DiaryOrigin
                originInfo={originInfo}
                sidebarCard={sidebarCard}
                isHide={true}
              
              />
            )}
            {colorInfo?.C18 !== "#N/A" && sidebarCard?.colorCauseBox && (
              <DiaryColorCause
                colorInfo={colorInfo}
                gia_report={gia_report}
                data={data}
                color={color}
                sidebarCard={sidebarCard}
                isHide={true}
             
              />
            )}
            {saturationInfo?.C30 !== "#N/A" && sidebarCard?.saturationBox && (
              <DiarySaturation
                data={data}
                saturationInfo={saturationInfo}
                color={color}
                sidebarCard={sidebarCard}
              
                range_slider={range_slider}
                isHide={true}
              />
            )}
            {shapInfo?.C24 !== "#N/A" && sidebarCard?.shapeBox && (
              <DiaryShape
                shapInfo={shapInfo}
                diaryInfo={reportData?.diaryInfo}
                data={data}
                imgScaleVal={imgScaleVal}
                sidebarCard={sidebarCard}
                isHide={true}

                gia_report={gia_report}
              />
            )}
            {profilerInfo?.C58 !== "#N/A" && sidebarCard?.surfaceAreaBox && (
              <FaceUpDiary
                profilerInfo={profilerInfo}
                data={data}
                gia_report={gia_report}
                imgScaleVal={imgScaleVal}
                range_slider={range_slider}
                sidebarCard={sidebarCard}
                isHide={true}
                weightInfo={weightInfo}
            
              />
            )}
            {(weightInfo?.C34 !== "#N/A" && range_slider?.showWeightCard ==="TRUE") && sidebarCard?.weightBox && (
                <NewDiaryWeight
                  profilerInfo={profilerInfo}
                  data={data}
                  gia_report={gia_report}
                  imgScaleVal={imgScaleVal}
                  range_slider={range_slider}
                  sidebarCard={sidebarCard}
                  weightInfo={weightInfo}
                  isHide={true}
                />
              )}
           
            {clarityInfo?.C38 !== "#N/A" && sidebarCard?.clarityBox && (
              <DiaryClarity
                clarityInfo={clarityInfo}
                color={color}
                textColor={textColor}
                sidebarCard={sidebarCard}
                isHide={true}
              />
            )}
            {gia_report?.results?.fluorescence !== "None" &&
              florescenceInfo?.C42 !== "#N/A" &&
              sidebarCard?.fluorescenceBox && (
                <DiaryFluorescence
                  florescenceInfo={florescenceInfo}
                  data={data}
                  sidebarCard={sidebarCard}
                  isHide={true}
                />
              )}

            {range_slider?.showRarityCard === "TRUE" &&
              (rarityInfo?.C62 !== "#N/A" || rarityInfo?.C64 !== "#N/A") &&
              sidebarCard?.rarityBox && (
                <DiaryRarity
                  rarityInfo={rarityInfo}
                  data={data}
                  color={color}
                  sidebarCard={sidebarCard}
               
                  reportId={reportId?.Id}
                  isHide={true}
                  goToRairtyReport={goToRairtyReport}
                  stringDate={dateSetting?.stringDate}

                />
              )}
            {(data?.color === "Blue" ||
              data?.color === "Pink" ||
              data?.color === "Yellow" ||
              data?.color === "Pink - Purplish Pink" ||
              data?.color === "Pink/Purple/ish Pink") &&
              (data?.saturation === "Fancy" ||
                data?.saturation === "Fancy Intense" ||
                data?.saturation === "Fancy Vivid") &&
              parseFloat(data?.weight) >= 1 &&
              sidebarCard?.indexBox && (
                <DiaryDiamondIndex
                  color={color}
                  closestDiamondValues={closestDiamondValues}
                  closestDiamondDates={closestDiamondDates}
                  colorName={reportData?.data?.color || ""}
                  saturationName={reportData?.data?.saturation || ""}
                  indexResult={reportData?.indexResult || ""}
                  sidebarCard={sidebarCard}
                  isHide={true}
                  appreciation={appreciation}
               
                />
              )}

            {!(
              famousDiamondInfo?.C50 === "#N/A" ||
              famousDiamondInfo?.C50?.includes("#NUM!")
            ) ? (
              <LuxuryHistory
                famousDiamondInfo={famousDiamondInfo}
                famousView={famousView[0] || []}
                sidebarCard={sidebarCard}
                isHide={true}
             
              />
            ) : (
              ""
            )}
              <ScrollToTop scrollClass={"scroll-up-share2"}/>
            <span className="rarity-tool-text">
              The data on the Fancy Color Diamond Diary© may change from time to
              time and is not a guarantee or a reflection of the diamond's value. The origin of the diamond is
              provided solely according to the input of the person for whom the report was produced. The gemological
              description of the diamond is obtained from the GIA website. To maintain the accuracy and currency of the information,
              it is advised to periodically update the diary, especially prior to each use.
              For additional information about the data and other limitations, please contact us at{" "}
              <a href="mailto:customers@fcresearch.org">
                customers@fcresearch.org
              </a>
              .
            </span>
           <p className="report-timestamp   d-block d-md-none">

<span>Report generated on {formatDateReport(reportData?.timestamp)} </span>
<br/>
<span> Last data update on {formatDate(range_slider?.textSheetData?.H12)}</span>
</p>
                <p className="report-timestamp d-none d-md-block">
                     Report generated on {formatDateReport(reportData?.timestamp)} |
                     Last data update on {formatDate(range_slider?.textSheetData?.H12)}
                    </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareDiaryReport;
