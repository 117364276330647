import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../footer/footer";
import FcrfContext from "../../Context";
import leftArrow from "../../assets/images/rarity/icon-arrow-left.svg";
import { queryString } from "../../utils/queryString";

const TrueFaceUpLayout = ({
  header,
  children,
  className,
  activeTab,
  showArgyleContent,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const getContext = useContext(FcrfContext);
  const setContext = useContext(FcrfContext);
  const { steps, setFormProgress } = getContext;
  const { pId, from } = queryString();

  const handleOpen = () => {
    if (location.pathname === "/report") {
      return navigate("/saved-report?report");
    }
    if (location?.pathname === "/argyle-report") {
      return navigate("/saved-argyle-report?argyle-report");
    }
    if (location?.pathname === "/" && activeTab === "tab2") {
      return navigate("/saved-argyle-report");
    }
    navigate("/saved-report");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const backToHome = () => {
    window.location.href = `${process.env.REACT_APP_WP_BASE_URL}/rarity-evaluator`;
  };

  const handleBackRairity = () => {
    // console.log(location.pathname);

    if (location.pathname === "/true-face-up-history") {
      return navigate(-1);
    }
    if (from === "diary") {
      navigate("/diary-report", {
        state: {
          Id: pId,
          action: "Get",
        },
      });
      return;
    }

    if (location.pathname === "/saved-report") {
      navigate(-1);
      return;
    }
    if (location.pathname === "/rarity-save-history") {
      navigate(-1);
      return;
    }
    if (location?.pathname === "/argyle-save-history") {
      navigate(-1);
      return;
    }

    if (
      location.pathname === "/saved-argyle-report" &&
      location.search === "?argyle-report"
    ) {
      // console.log("jheduyg>>");
      navigate(-1);
    }

    if (location.pathname === "/saved-argyle-report") {
      navigate("/", {
        state: {
          tab: "tab2",
          showArgyle: showArgyleContent,
        },
      });

      return;
    }
    if (location.pathname === "/argyle-report") {
      navigate("/", {
        state: {
          showArgyle: false,
          tab: "tab2",
        },
      });
      window.location.reload();
      return;
    }
    if (location.pathname === "/report" && steps > 1) {
      navigate("/", {
        state: {
          tab: "tab1",
          showGia: false,
        },
      });
      window.location.reload();
      return;
    }
    if (location.pathname === "/report") {
      navigate(-1);

      return;
    }

    if (location.pathname === "/report") {
      navigate("/", {
        state: {
          tab: "tab1",
          showGia: false,
        },
      });
      window.location.reload();
      return;
    }

    if (steps > 1) {
      const nextStep = steps - 1;
      setContext.setSetps(nextStep);
      if (nextStep === 1) {
        navigate(location.path);
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 2) {
        window.history.pushState("2", null, "?Step=1");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 3) {
        window.history.pushState("3", null, "?Step=2");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 4) {
        window.history.pushState("4", null, "?Step=3");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 5) {
        window.history.pushState("5", null, "?Step=4");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 6) {
        setContext.setSetps(1);
        navigate(location.path);
      }
    }
  };

  return (
    <>
      <div
        className={`rarity-header ${header}  ${
          steps !== 1 && location.pathname === "/" && "rarity-top-white-header"
        } `}
      >
        <div className="container true-face-up-container">
          <div
            className={`rarity-top-header 
            ${
              steps === 1 && location.pathname === "/" && activeTab === "tab2"
                ? "justify-content-end1 "
                : "active-header "
            }${
              location.pathname === "/saved-report" ||
              location.pathname === "/saved-argyle-report" ||
              location.pathname === "/rarity-save-history" ||
              location.pathname === "/argyle-save-history"
                ? " header-argyle-rarity-save-report"
                : ""
            }
            `}
          >
            {steps === 1 &&
            (location.pathname === "/" || location.pathname === "/argyle") ? (
              location.pathname === "/argyle" && (
                <div
                  className="back-btn"
                  onClick={() => backToHome()}
                  role="presentation"
                >
                  {" "}
                  <img src={leftArrow} alt="" />
                  BACK
                </div>
              )
            ) : (
              <div
                className="back-btn"
                onClick={() => handleBackRairity()}
                role="presentation"
              >
                <img src={leftArrow} alt="" />
                BACK
              </div>
            )}
            <div
              className={`saved-report ${className} ${
                location.pathname === "/saved-report" ||
                location.pathname === "/saved-argyle-report" ||
                location.pathname === "/rarity-save-history"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleOpen()}
            >
              {location.pathname === "/argyle-report" ||
              location?.pathname === "/argyle" ||
              location.pathname === "/saved-argyle-report"
                ? ""
                : location.pathname === "/rarity-save-history"
                ? "Rarity Saved History"
                : "Rarity Search History "}
              {location.pathname === "/rarity-save-history" ||
              location.pathname === "/saved-report" ? (
                <SavedIcon />
              ) : location.pathname === "/saved-argyle-report" ? (
                <SavedIcon />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="content">{children}</div>
      <Footer />
    </>
  );
};

export default TrueFaceUpLayout;

const SavedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 3.89281V21.2318C4 21.7602 4.42983 22.19 4.95824 22.19C5.1554 22.19 5.34863 22.1309 5.51031 22.0165L11.5713 17.7734L17.6322 22.0165C17.7939 22.1309 17.9871 22.19 18.1843 22.19C18.7127 22.19 19.1425 21.7602 19.1425 21.2318V3.89281C19.1425 2.84782 18.2947 2 17.2497 2H5.89281C4.84782 2 4 2.84782 4 3.89281Z"
        fill="#ffffff"
      />
    </svg>
  );
};
