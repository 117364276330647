/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect,  } from "react";
import Loader from "../loader/loader";
import RarityToolCard from "./rarityToolCard";
import GemologicalCharacteristics from "./gemologicalCharacteristics";
import { withoutAuth } from "../../config/axios";
import { resultFormating } from "../../utils/resultFormating";
import { queryString } from "../../utils/queryString";
import { Helmet } from "react-helmet";
import { getFormattedDate } from "../../utils/dateUtils";
import { clientPath } from "../../config/key";
import { DiaryDiamondColors, DiaryDiamondShapes } from "../../utils/dairyDiamondsDetail";
import { useLocation, useParams } from "react-router-dom";
import { decryptString, extractShareIdByKey } from "../../utils/decryptText";
import RarityHighlightedBox from "./rarityHighlightedBox";
import RarityInfo from "./rarityInfo";
import "./rarityReport.css";

import ScrollToTop from "../common/scrolltotop";
import MarketDistribution from "./marketDistribution";
const ShareRarityReport = () => {
  const { id } = queryString();
  const [loading, setLoading] = useState(true);
  const [characteristics, setCharacteristics] = useState({});
  const [bubble_texts, setBubbleText] = useState({});
  const [range_slider, setRangeSlider] = useState({});
  const [labelName, setLabelName] = useState([]);
  const [tooltip_text, setTooltipText] = useState([]);
  const [labelValue, setLabelValue] = useState([]);
  const[animationText,setAnimationText]=useState(null)
  const [reportData, setReportData] = useState({});
  const [report_number, setReportNumber] = useState(null);
  const [, setTime] = useState(null);
  const [, setImgScaleVal] = useState(1);
  const [metaImgData, setMetaImgData] = useState({
    color:"",
    shape:""
  })
  const sharerarityIds = useParams();
  const [companyLogo, setCompanyLogo] = useState("");
  const [custom_branding, setCustomBranding] = useState("0");
  const [showContent, setShowContent] = useState(false);
  // const [showGemological, setShowGemological] = useState(false);
  const location = useLocation();


  useEffect(() => {
    async function fetchData(_id) {
      const {postId,createdBy} = await getReportById(_id);
      await sharePageLoaded(postId,createdBy);
    }
    const params = location.search;
    // Check for the legacy link
    if (params?.includes("?id")) {
      const decData = decryptString(id);
      const shareIdvalue = extractShareIdByKey(decData, "Id");
      fetchData(shareIdvalue);
    } else {
      // Check for the updated link
      const rarityPayload = { id: sharerarityIds?.id };
      fetchData(rarityPayload.id);
    }
  }, [id]);



  const AnimateStringData = (inputString) => {
    let Text = inputString?.split("#") || [];
    const parts = Text[1]?.split("~");

    if (parts?.length === 2) {

      const secondString = parts[1].trim().replace(/,/g,"")
      // console.log(secondString);
      setAnimationText(Number(secondString))
      return secondString;
    } else {
      setAnimationText(null)
      return null;
    }
  };
  const sharePageLoaded = async (postId,createdBy) => {
    try {
      await withoutAuth().post("report/share-link-open/Rarity", {
        reportId: postId,
        createdBy,
        shareUrl: window.location.href,
      });
    } catch (error) {
      console.error("error in open link===", error);
    }
  };

  const getReportById = async (id) => {
    try {
      const response = await withoutAuth().post("report/getreportbyId", {
        id,
      });
      const { getReport, companyInfo,createdBy} = response?.data;
      const reportData = getReport?.report || {};
      if (reportData) {
        const {  data, graph_texts, bubble_texts, range_slider } =
          reportData;
        const { labelname, graph_tooltip_text, labelvalue } = resultFormating(
          graph_texts,
          range_slider,
          data
        );
       
        const times = getFormattedDate(reportData.timestamp);
        setCompanyLogo(companyInfo?.companyLogo || "")
        setCustomBranding(companyInfo?.customBranding || "0")
        setReportData(reportData);
        setReportNumber(reportData?.data?.report_number);
        setLabelName(labelname);
        setLabelValue(labelvalue);
        setTooltipText(graph_tooltip_text);
        setBubbleText(bubble_texts);
        AnimateStringData(bubble_texts?.D18)
        setRangeSlider(range_slider);
        setCharacteristics(data);
        setTime(times);

        const Imgshape= DiaryDiamondShapes.filter((elem) => getReport?.report?.data?.shape === elem.value)
        const imgColorShape = DiaryDiamondColors.filter((elem) => getReport?.report.data?.color === elem.value);
        setMetaImgData({color:imgColorShape[0]?.img , shape: Imgshape[0]?.img})

      }
      setLoading(false);
      return ({postId:reportData?.postId,createdBy});
    } catch (error) {
      console.log("error", error);
    }
  };

 
  const sizeValueInit =
    reportData?.gia_report?.results?.data?.measurements?.length /
      reportData?.gia_report?.results?.data?.measurements?.width || 0;

  const sizeValue =
    Number(sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) || 0;

  // console.log("sizeValueInit>>>", sizeValueInit);
  // console.log("sizeValue>>>", sizeValue);

  useEffect(() => {
    if (
      reportData?.data?.shapeCategory === "Cushion" ||
      reportData?.data?.shapeCategory === "Radiant"
    ) {
      if (sizeValue <= 1.05) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
        setImgScaleVal(1.075);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(1.15);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(1.225);
      } else if (sizeValue >= 1.35) {
        setImgScaleVal(1.3);
      }
    } else if (reportData?.data?.shapeCategory === "Emerald") {
      if (sizeValue <= 1.14) {
        setImgScaleVal(0.775);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(0.85);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(0.925);
      } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.45) {
        setImgScaleVal(1.075);
      }
    }
  }, [sizeValue]);

  const formatDateReport = (input) => {
    const dateObject = new Date(input);
    if (isNaN(dateObject.getTime())) {
      return 'Invalid Date';
    }
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const lastTwoDigitsOfYear=year % 100;
    const formattedDate = `${day}.${month}.${lastTwoDigitsOfYear}`;
    return formattedDate;
  };
  const formatDate = (inputDate) => {
    const dateComponents = inputDate?.match(/(\w+) (\d+)(?:st|nd|rd|th), (\d+)/);
    if (!dateComponents) {
      return 'Invalid Date';
    }
    return `${dateComponents[1].slice(0, 3)} ${ dateComponents[2]}, ${dateComponents[3]}`;
  };


  const formatDateWebReport = (input) => {
    const dateObject = new Date(input);
    if (isNaN(dateObject.getTime())) {
      return "Invalid Date";
    }
  
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
  
    // Get month name
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[month - 1];
  
    // Function to get ordinal suffix (1st, 2nd, 3rd, etc.)
    const getOrdinalSuffix = (day) => {
      if (day >= 11 && day <= 13) return `${day}th`; // Special case for 11th, 12th, 13th
      switch (day % 10) {
        case 1: return `${day}st`;
        case 2: return `${day}nd`;
        case 3: return `${day}rd`;
        default: return `${day}th`;
      }
    };
  
    const formattedDate = `${monthName} ${getOrdinalSuffix(day)}, ${year}`;
    return formattedDate;
  }; 
  const highlightText =  reportData?.bubble_texts?.D18?.split("#")[1]?.split(" ")?.at(-1) || '';
    // const highlightYear =  reportData?.bubble_texts?.H18?.split("#")[1]
  return (
    <>
      <Helmet>
        <title>FCRF - Rarity Report</title>

        <meta property="og:title" content="Rarity Report"/>
        <meta property="og:description" content="your Rarity Report"/>

        <meta property="og:image" content={`${clientPath}/images/diamond-${metaImgData.color}-${metaImgData.shape}.svg`} />
      </Helmet>
      {loading && <Loader type={"progress"} />}

      <div className="rarity-report-page shared-rarity-report-page">
        <div className="rarity-report-head"></div>
        <div className="rarity-report-content">
          <div className="container">
            <div className="rarity-tool">

            <div className="rarity-tool-grid updatedrarity-tool-grid ">
            <div className="updated_rarity_pdf">
                    <div className="rarity_report_top">
                      <div className="rarity_report_top_left">
                        <div className="rarity-fcrf-logo">
                          <img  src={`${ clientPath }/icons/FCRF_logo.svg`} alt="Logo" />
                        </div>
                        <div className="rarity_report_top_left_desc">
                         
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Carat weight:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">{parseFloat(reportData?.data?.weight).toFixed(2)}</div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Saturation:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">{reportData?.data?.saturation}</div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Color:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">
                            {reportData?.data?.report_number === "" || !reportData?.data?.report_number ? reportData?.data?.color : `${reportData?.data?.colorName}${(reportData?.data?.color ==="Chameleon" && reportData?.data?.report_number) ? "*" :""}`} 
                            </div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Shape:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">
                            {reportData?.data?.report_number === "" || !reportData?.data?.report_number ? reportData?.data?.shape : reportData?.range_slider?.shapeName}
                            </div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Clarity:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">{reportData?.data?.report_number === "" || !reportData?.data?.report_number ? reportData?.data?.clarity : reportData?.data?.giaclarity}</div>
                          </p>
                       {
                        !(reportData?.data?.report_number === "" || !reportData?.data?.report_number) ? (
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`} style={{
                            color: '#767676'
                          }}>
                            <div className="rarity-gemological-text">
                              GIA
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`} style={{borderBottom: '3px dotted #767676'}}></div>
                            <div className="rarity-gemological-text">
                            No. {reportData?.data?.report_number}
                            </div>
                          </p>
                        ) : null
                       }

{(reportData?.data?.color ==="Chameleon" && reportData?.data?.report_number) &&  <div>
                            <span>*Chameleon</span>
                          </div> }

                        </div>
                      </div>
                      <div className="rarity_report_top_right">
                        <div className="diamond-desc-img">
                          <div className="rarity-right-title">
                            <h4>The Rarity Report</h4> 
                            <span>
                            <CIcon />
                            </span>
                           
                          </div>

                          <div className="rarity-right-img-data">
                            <p className="report-date m-0">
                              {formatDateWebReport(reportData?.timestamp)}
                            </p>
                            <p className="report-number m-0">
                              Report {reportData?.postId}
                            </p>
                          </div>

                          <div className="divider"></div>

                          

                          <div className={`rarity-note  ${!(report_number ===""|| !report_number) && "rarity-note-for-gia"}`}>
                            Rarity data refers to a natural color diamond with
                            the described gemological properties
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="diamond-grade ">
                      <div className="grade-section">
                        <p className="text-block">
                          <span className="main-text">rarity</span>
                          <span className="highlight-text">
                            ONE IN {highlightText}
                          </span>
                        </p>

                        <p className="text-block">
                          <span className="main-text">market entry</span>
                          <span className="highlight-text market-highlight">
                            {" "}
                            {reportData?.bubble_texts?.H18?.replaceAll(
                              "#",
                              ""
                            )}{" "}
                          </span>

                        </p>
                      </div>
                    </div>

                    <div className="graph_section_rarity">
                      <div className="graph_section_rarity-left">
                        <h6 className="graph_section_text">
                          Market Distribution Percentage
                        </h6>
                        <div className="graph_section_chartdata">
                          <div className="updatedchart">
                            <MarketDistribution
                              graph_texts={tooltip_text}
                              labelValue={labelValue}
                              labelName={labelName}
                              loading={false}
                            />
                          </div>
                          <div>
                            <div className="divider"></div>

                            <h1 className="update-text">
                              The Fancy Color Research Foundation
                            </h1>
                          </div>
                        </div>
                      </div>

                      <div className="graph_section_rarity-right graph_section_rarity-left">
                        <h6 className="graph_section_text">
                          Denomination Brackets
                        </h6>
                        <div className="market-distribution-top">
                          <div className="market-distribution">
                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Remarkable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">Once</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">every 25y</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Remarkable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Remarkable
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Exceptional"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                {" "}
                                <div className="tab-text-card">Once</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">
                                  every 13 - 24y
                                </div>{" "}
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Exceptional"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Exceptional
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extremely Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">Once</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">
                                  every 1 - 12y
                                </div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extremely Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Extremely Rare
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">1-3</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Very Rare
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">3-10</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Rare
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extraordinary"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">10-40</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extraordinary"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Extraordinary
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">40-180</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Very Special
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">180-400</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Special
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Outstanding"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">400-900</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Outstanding"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Outstanding
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Notable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">900-10,000</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Notable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Notable
                              </span>
                            </div>
                          </div>
                          <div className="divider-mt">
                            <div className="divider"></div>
                            <h1 className="update-text">
                              Last data update on{" "}
                              {formatDate(reportData?.textSheetData?.H12)}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!(custom_branding === "1" && companyLogo) ? (
                       <div className="rarity_report_top update-rarity-bottom">
                       <p>
                         The data acquired from The Fancy Color Diamond Rarity
                         Report©. may change from time to time and is not a
                         guarantee nor a reflection of the diamond’s value. For
                         additional information regarding the data, and other
                         limitations, contact us at:{" "}
                         <a href="mailto:customers@fcresearch.org">
                           customers@fcresearch.org
                         </a>
                       </p>
                     </div>  
                    ) :
                    (
                      <div className="share-rarity-bottom">
                        <div className="share-rarity-bottom-text update-rarity-bottom"> 
                        <p>
                         The data acquired from The Fancy Color Diamond Rarity
                         Report©. may change from time to time and is not a
                         guarantee nor a reflection of the diamond’s value. For
                         additional information regarding the data, and other
                         limitations, contact us at:{" "}
                         <a href="mailto:customers@fcresearch.org">
                           customers@fcresearch.org
                         </a>
                       </p>
                        </div>
                        <div className="share-rarity-bottom-logo">
                        <img
                              src={companyLogo}
                              alt="Bottom Logo"
                            />
                        </div>
                       
                      
                    </div>
                    )}
                    
                   
                  </div>
                </div>
                <div className="rarity-tool-grid old-rarity-report rarity-mob-updated">
                <div className="rarity-tool-left ">
                    <div>
                      <div className="rarity-heading-wrapper card-pd rarity-page-header">
                        <div className="rarity_report_top_heading ">
                         
                          <h1 className="relative rarity_report_head_top ">the rarity report

                          </h1>
                          <span>
                            <CIconMob />
                            </span>
                        </div>
                      </div>
                      <div className="rarity-right-img-data updated-mob-rarity">
                        <p className="report-date m-0">
                          {formatDateWebReport(reportData?.timestamp)}
                        </p>
                        <p className="report-number m-0">
                          Report {reportData?.postId}
                        </p>
                      </div>
                      <div className="divider update-divider"></div>
                  
                      <div className={`rarity-note update-rarity-note mt-0 ${!(report_number ===""|| !report_number) && "rarity-note-for-gia"}`}>
                        Rarity data refers to a natural color diamond with the
                        described gemological properties
                      </div>

                      <div className="rarity_report_gemological_properties">
                        <p>
                          {parseFloat(reportData?.data?.weight).toFixed(2)} ct,{" "}
                          {reportData?.data?.saturation},{" "}
                          {reportData?.data?.color === "Chameleon" &&
                          reportData?.data?.report_number
                            ? `${reportData?.data?.colorName} - Chameleon`
                            : !reportData?.data?.report_number
                            ? reportData?.data?.color
                            : reportData?.data?.colorName}
                          ,{" "}
                          {reportData?.data?.report_number === "" ||
                          !reportData?.data?.report_number
                            ? reportData?.data?.shape
                            : reportData?.range_slider?.shapeName}
                          ,{" "}
                          {reportData?.data?.report_number === "" ||
                          !reportData?.data?.report_number
                            ? reportData?.data?.clarity
                            : reportData?.data?.giaclarity}{" "}
                        </p>
                        {reportData?.data?.report_number && (
                          <span>GIA No. {reportData?.data?.report_number}</span>
                        )}
                      </div>
                    </div>

                    <div className="grade-mob-view">
                      <div className="rarity-highlighted-box highlight-box1">
                        <div className="text-block ">
                          <h6 className="main-text">rarity</h6>
                          <p className="highlight-text">
                            ONE IN {highlightText}
                          </p>
                        </div>
                      </div>

                      <div className="rarity-highlighted-box highlight-box1 highlight-box2">
                        <div className="text-block ">
                          <h6 className="main-text">market entry</h6>

                          <p className="highlight-text">
                            {reportData?.bubble_texts?.H18?.split("#")[0]}
                          </p>
                          <p className="highlight-text">
                            {reportData?.bubble_texts?.H18?.split("#")[1]}
                          </p>

                        </div>
                        {!showContent && (
                          <p
                            className="text-decoration-underline learn-about-text"
                            onClick={() => setShowContent(!showContent)}
                          >
                            learn about the rarity levels
                          </p>
                        )}

                        {
                          <div
                            className={`graph_section_rarity-right graph_section_rarity-left ${
                              showContent
                                ? "rarity-level-text-animation"
                                : "collapsed"
                            }`}
                          >
                            <h6 className="graph_section_text">
                              Denomination Brackets
                            </h6>
                            <div className="market-distribution-top">
                              <div className="market-distribution">
                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Remarkable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">Once</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">
                                      every 25y
                                    </div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Remarkable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Remarkable
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Exceptional"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    <div className="tab-text-card">Once</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">
                                      every 13 - 24y
                                    </div>{" "}
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Exceptional"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Exceptional
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extremely Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">Once</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">
                                      every 1 - 12y
                                    </div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extremely Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Extremely Rare
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">1-3</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Very Rare
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">3-10</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Rare
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extraordinary"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">10-40</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extraordinary"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Extraordinary
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">40-180</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Very Special
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">180-400</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Special
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Outstanding"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">400-900</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Outstanding"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Outstanding
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Notable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">
                                      900-10,000
                                    </div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Notable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Notable
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        </div>

                    </div>

                    <div className="card-pd card-height">
                      <GemologicalCharacteristics
                        graph_texts={tooltip_text}
                        labelValue={labelValue}
                        labelName={labelName}
                        loading={false}
                        section={"rarity"}
                      />
                    </div>
                    <div className="text-center">
                        <img
                              src={companyLogo}
                              alt="Logo"
                              className="rarity-share-logo"
                            />
                        </div>


                  </div>                


                  <div className="rarity-tool-right ">
                    <div className="rarity-time-report-no-wrapper grade-desktop-view">
                      <h5>{formatDateReport(reportData?.timestamp)}</h5>
                      <p>Report {reportData?.postId}</p>
                    </div>
                    <div className="grade-desktop-view cardHightRight1"  >
                      <RarityToolCard
                        cardDesc={
                          "Fancy Color Diamond appreciation revolves around beauty and rarity. The Rarity Report illustrates the market prevalence of a polished diamond. Whether a diamond is one of several hundred or is introduced to the market once a decade, rarity was, and always will be, a part of the epic story of a fancy color diamond."
                        }
                        loading={false}
                        line={2}
                        className="rarity-left-content-alignment"
                      />
                    </div>
                    <div className={`grade-desktop-view ${characteristics.report_number ? "share-rarity-grade" : "right_grade"}`}>
                      <RarityHighlightedBox
                        range_slider={range_slider}
                        cardDesc={bubble_texts?.H18}
                        animationText={animationText}
                        loading={loading}
                      />
                    </div>

                    <RarityInfo range_slider={range_slider} />

                  </div>
                </div>
                <div>
                      <div className="rarity-tool-bottom-text-padding1">

                        <p className="rarity-tool-text mb-2">
                          The data acquired from The Fancy Color Diamond Rarity Report©. may change from time to time and is not a guarantee nor a reflection of the diamond’s value. For additional information regarding the data, and other limitations, contact us at: customers@fcresearch.org
                        </p>



                        <p className="report-timestamp   rarity-report-time-stamp grade-mob-view">


                          <span className="update-text last-date"> Last data update on {formatDate(reportData?.textSheetData?.H12 || reportData?.range_slider?.textSheetData?.H12)}</span>
                        </p>

                      </div>
                    </div>

              <div className="report-action-footer ">
                <div className="rarity-header-section grade-mob-view"></div>
              </div>

              <ScrollToTop scrollClass={"scroll-up-share"}/>

             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default ShareRarityReport;

const CIconMob = () => {
  return (
<svg width="9" height="9" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1559 5.93679C11.1559 8.97316 8.75437 11.4236 5.73015 11.4236C2.72052 11.4236 0.45 8.98821 0.45 5.93679C0.45 2.88536 2.72052 0.45 5.73015 0.45C8.75437 0.45 11.1559 2.90042 11.1559 5.93679Z" fill="white" stroke="black" stroke-width="0.9"/>
<path d="M8.27954 4.68722C8.23072 4.51634 8.1819 4.36987 8.13308 4.24782C8.08426 4.11966 8.03238 4.00676 7.97746 3.90911C7.92863 3.80537 7.87066 3.71382 7.80353 3.63449C7.7425 3.55515 7.66927 3.47276 7.58383 3.38733C7.37633 3.17373 7.13832 3.01506 6.8698 2.91131C6.60738 2.80146 6.32665 2.74653 6.02762 2.74653C5.64924 2.74653 5.30444 2.81977 4.9932 2.96623C4.68806 3.1127 4.42564 3.32325 4.20594 3.59787C3.99234 3.86639 3.82756 4.19289 3.71161 4.57737C3.59566 4.96184 3.53768 5.38904 3.53768 5.85895C3.53768 6.46923 3.62007 7.00323 3.78484 7.46093C3.94962 7.91864 4.18458 8.27566 4.48972 8.53197C4.94742 8.89814 5.49362 9.08122 6.12831 9.08122C6.56161 9.08122 6.96134 8.99884 7.32751 8.83406C7.69368 8.66929 8.07205 8.40076 8.46263 8.02849L8.64571 8.21158C8.32837 8.63267 7.92253 8.95917 7.42821 9.19107C6.93998 9.42298 6.39684 9.53893 5.79876 9.53893C5.28003 9.53893 4.80706 9.45349 4.37987 9.28262C3.95267 9.11174 3.5865 8.87373 3.28136 8.56859C2.97622 8.25735 2.73822 7.88508 2.56734 7.45178C2.40256 7.01848 2.32018 6.53636 2.32018 6.00542C2.32018 5.40124 2.42392 4.8581 2.63142 4.37598C2.83891 3.88775 3.14405 3.47887 3.54683 3.14932C3.86418 2.893 4.22119 2.69466 4.61787 2.55429C5.01455 2.40783 5.41429 2.33459 5.81707 2.33459C6.33581 2.33459 6.84844 2.42003 7.35497 2.59091C7.52585 2.65194 7.66316 2.68245 7.76691 2.68245C7.87066 2.68245 7.95915 2.65194 8.03238 2.59091C8.11172 2.52988 8.16664 2.44444 8.19716 2.33459H8.41686L8.51755 4.68722H8.27954Z" fill="black"/>
</svg>


  );
};

const CIcon = () => {
  return (
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1559 5.93679C11.1559 8.97316 8.75437 11.4236 5.73015 11.4236C2.72052 11.4236 0.45 8.98821 0.45 5.93679C0.45 2.88536 2.72052 0.45 5.73015 0.45C8.75437 0.45 11.1559 2.90042 11.1559 5.93679Z" fill="white" stroke="black" stroke-width="0.9"/>
<path d="M8.27954 4.68722C8.23072 4.51634 8.1819 4.36987 8.13308 4.24782C8.08426 4.11966 8.03238 4.00676 7.97746 3.90911C7.92863 3.80537 7.87066 3.71382 7.80353 3.63449C7.7425 3.55515 7.66927 3.47276 7.58383 3.38733C7.37633 3.17373 7.13832 3.01506 6.8698 2.91131C6.60738 2.80146 6.32665 2.74653 6.02762 2.74653C5.64924 2.74653 5.30444 2.81977 4.9932 2.96623C4.68806 3.1127 4.42564 3.32325 4.20594 3.59787C3.99234 3.86639 3.82756 4.19289 3.71161 4.57737C3.59566 4.96184 3.53768 5.38904 3.53768 5.85895C3.53768 6.46923 3.62007 7.00323 3.78484 7.46093C3.94962 7.91864 4.18458 8.27566 4.48972 8.53197C4.94742 8.89814 5.49362 9.08122 6.12831 9.08122C6.56161 9.08122 6.96134 8.99884 7.32751 8.83406C7.69368 8.66929 8.07205 8.40076 8.46263 8.02849L8.64571 8.21158C8.32837 8.63267 7.92253 8.95917 7.42821 9.19107C6.93998 9.42298 6.39684 9.53893 5.79876 9.53893C5.28003 9.53893 4.80706 9.45349 4.37987 9.28262C3.95267 9.11174 3.5865 8.87373 3.28136 8.56859C2.97622 8.25735 2.73822 7.88508 2.56734 7.45178C2.40256 7.01848 2.32018 6.53636 2.32018 6.00542C2.32018 5.40124 2.42392 4.8581 2.63142 4.37598C2.83891 3.88775 3.14405 3.47887 3.54683 3.14932C3.86418 2.893 4.22119 2.69466 4.61787 2.55429C5.01455 2.40783 5.41429 2.33459 5.81707 2.33459C6.33581 2.33459 6.84844 2.42003 7.35497 2.59091C7.52585 2.65194 7.66316 2.68245 7.76691 2.68245C7.87066 2.68245 7.95915 2.65194 8.03238 2.59091C8.11172 2.52988 8.16664 2.44444 8.19716 2.33459H8.41686L8.51755 4.68722H8.27954Z" fill="black"/>
</svg>


  );
};




