export const getYear=(date)=>{
  if(date){
    const splitDate = date.split('-');
      date = splitDate[2]
  }else{
    date ='';
  }
return date;
}

export const getQuarterWithYear=(date)=>{
  let currentYear = ''
  if(date){
      const splitDate = date.split('-')
      const currentMonth = splitDate[1];
       currentYear = splitDate[2];
      if(currentMonth >=1 && currentMonth<=3){
          date = `Q1`
      }else if(currentMonth >=4 && currentMonth<=6){
          date = `Q2`
      }else if(currentMonth >=7 && currentMonth<=9){
          date = `Q3`
      }else{
          date = `Q4`
      }
  }
  return `${date} of ${currentYear}`
}

const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
// For the format-     Jun 20th 2023
export const getFormattedDate=(dateValue)=>{
  const today = new Date(dateValue);
  const date = today.getDate();
  const month = months[today.getMonth()];
  const year = today.getFullYear();
  
  let formattedDate = `${month} ${date}`;
  
  // Add "st", "nd", "rd", or "th" to the date
  if (date >= 11 && date <= 13) {
    formattedDate += "th";
  } else {
    switch (date % 10) {
      case 1: formattedDate += "st"; break;
      case 2: formattedDate += "nd"; break;
      case 3: formattedDate += "rd"; break;
      default: formattedDate += "th"; break;
    }
  }
  
  return formattedDate += ` ${year}`;
}


export const getFormattedIsoDate=(dateValue)=>{
// Step 1: Parse the date string
const dateComponents = dateValue?.match(/(\w+) (\d+)(?:st|nd|rd|th), (\d+)/);

// Step 2: Convert the month name to its numeric representation
const months = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};
const month = months[dateComponents[1]];

// Step 3: Create a new Date object
const date = new Date(dateComponents[3], month, dateComponents[2]);

// Step 4: Convert to ISO format
const isoDate = date.toISOString();
return isoDate;
}

export const formatDateReport = (input) => {
  const dateObject = new Date(input);
  if (isNaN(dateObject.getTime())) {
    return 'Invalid Date';
  }
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${
    months[date.getMonth()] + " " + date.getDate() + ", '" + date.getFullYear() % 100
  }`;
  return formattedDate;
};