import React from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { SVG1 } from "./svgData";
import "./vertical.css";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { wpPath } from "../../config/key";

const VerticalDropdown = ({ dropdownItems,handleCheckLogin ,loader}) => {
  const VerticalDropdownItem = ({
    icon: Icon,
    route,
    label,
    sharefun: Sharefun,
    urlValue,
  }) => {
    const navigate = useNavigate();

    const handleClick = () => {
      if (loader&&handleCheckLogin&&(localStorage.getItem("fcrf@token") === "undefined" ||
      !localStorage.getItem("fcrf@token") )
      ) {
        return handleCheckLogin();
      }
      if (route) {
        if(label === "Report Settings" ||label === "Ask for Valuation"){

          window.parent.postMessage({ type: 'navigate', url: `${wpPath}${route}` }, '*');
           }
        else{

            navigate(route);
        }
      } else if ((label === "Copy Report Link" ||label === "Saved Reports") && (urlValue ||Sharefun)) {
        Sharefun();
      }
    };


   

    return (
      <>
        {label === "Copy Report Link" && urlValue ? (
          <CopyToClipboard text={urlValue} >
            <Dropdown.Item as="button" onClick={handleClick}>
              {Icon && <Icon />} {label}
            </Dropdown.Item>
          </CopyToClipboard>
        ) : (
          <Dropdown.Item as="button"  onClick={handleClick}>
            {Icon && <Icon />}{label.includes("credit-loading") ? <>
    Credits: <Spinner animation="border" size="sm" className="credit-spinner" ml={2} />
  </> : label}
          </Dropdown.Item>
        )}
      </>
    );
  };

  return (
    <Dropdown
      className="rarity-dropdown-wrapper"
      align="end"
      id="dropdown-menu-align-end"
    >
      <Dropdown.Toggle
        className="custom-toggle"
        variant="link"
        id="dropdown-basic"
      >
        <SVG1 />
      </Dropdown.Toggle>
      <Dropdown.Menu className="custom-transform-menu-container">
        {dropdownItems &&
          dropdownItems.map((item, index) => (
            <React.Fragment key={index}>
              <VerticalDropdownItem {...item} />
              {index === 1 && dropdownItems.length > 3 && <Dropdown.Divider />}
            </React.Fragment>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default VerticalDropdown;
