import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { decryptString, extractShareIdByKey } from "../../utils/decryptText";
import { withoutAuth } from "../../config/axios";
import { clientPath } from "../../config/key";
import {
  DiaryDiamondColors,
  DiaryDiamondShapes,
} from "../../utils/dairyDiamondsDetail";
import Loader from "../loader/loader";
import { Helmet } from "react-helmet";
import { UAParser } from "ua-parser-js";
import arrowTop from "../../assets/images/arrowDown.svg";
import arrowLeft from "../../assets/images/arrowLeft.svg";
import { formatDate } from "../../utils/dateUtils";

const ShareTrueFacePage = () => {
  const { id } = useParams();
  const [reportData, setReportData] = useState({});
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [viewOnce, setViewOnce] = useState(false);
  const [imgScaleVal, setImgScaleVal] = useState(1);

  useEffect(() => {
    async function fetchData(_id) {
      const { postId, reportId, type, createdBy } = await getReportById(_id);

      await sharePageLoaded(postId, reportId, type, createdBy);
    }
    const params = location.search;
    if (params?.includes("?id")) {
      const decData = decryptString(id);
      const shareIdvalue = extractShareIdByKey(decData, "Id");
      fetchData(shareIdvalue);
    } else {
      // Check for the updated link
      fetchData(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const sharePageLoaded = async (postId, reportId, type, createdBy) => {
    try {
      const ua = navigator.userAgent;
      const [ipResponse, uaResult] = await Promise.all([
        fetch("https://api.ipify.org?format=json"),
        new Promise((resolve) => resolve(new UAParser(ua).getResult())),
      ]);

      const { ip } = await ipResponse.json();

      const services = {
        IP: ip,
        "Operating System": uaResult.os.name,
        Browser: uaResult.browser.name,
        Device: uaResult.device.model || "Unknown",
      };
      await withoutAuth().post("truefaceup/share-link-open/TrueFaceUp", {
        reportId: reportId,
        postId,
        shareUrl: window.location.href,
        services,
        faceupType: type,
        createdBy,
      });
    } catch (error) {
      console.error("error in open link===", error);
    }
  };

  const getReportById = async (id) => {
    try {
      setLoader(true);
      const response = await withoutAuth().post(
        "truefaceup/get-faceup-report-by-id",
        {
          id,
        }
      );
      const sizeValueInit =
        response.data?.getReport?.payload?.length /
          response.data?.getReport?.payload?.width || 0;
      let sizeValue =
        sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] || 0;
      
      if (
        response.data?.getReport?.payload?.shape === "Cushion" ||
        response.data?.getReport?.payload?.shape === "Radiant"
      ) {
        if (sizeValue <= 1.05) {
          setImgScaleVal(1);
        } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
          setImgScaleVal(1.075);
        } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
          setImgScaleVal(1.15);
        } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
          setImgScaleVal(1.225);
        } else if (sizeValue >= 1.35) {
          setImgScaleVal(1.25);
        }
      } else if (response.data?.getReport?.payload?.shape === "Emerald") {
        if (sizeValue <= 1.14) {
          setImgScaleVal(0.775);
        } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
          setImgScaleVal(0.85);
        } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
          setImgScaleVal(0.925);
        } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
          setImgScaleVal(1);
        } else if (sizeValue >= 1.45) {
          setImgScaleVal(1.075);
        }
      }
      setTimeout(() => {
        setViewOnce(true);
      }, 1000);
      setLoader(false);
      const { getReport, createdBy } = response?.data;
      setReportData(getReport);
      const { postId, _id } = getReport || {};
      return {
        postId: postId,
        reportId: _id,
        type: getReport?.type,
        createdBy,
      };
    } catch (error) {
      console.log("error", error);
    }
  };


  function getScaleForImage(shape, ratio) {
    if (!shape || !ratio) return 1.0;
    ratio = Number(ratio).toFixed(2);

    const scaleMap = {
      Cushion: [
        { upto: 1.09, scale: 1 },
        { upto: 1.19, scale: 1.05 },
        { upto: 1.29, scale: 1.1 },
        { upto: Infinity, scale: 1.15 },
      ],
      Radiant: [
        { upto: 1.09, scale: 1 },
        { upto: 1.19, scale: 1.05 },
        { upto: 1.29, scale: 1.1 },
        { upto: Infinity, scale: 1.15 },
      ],
      Oval: [
        { upto: 1.29, scale: 0.75 },
        { upto: 1.39, scale: 0.83 },
        { upto: 1.44, scale: 0.91 },
        { upto: Infinity, scale: 1 },
      ],
      Heart: [
        { upto: 0.99, scale: 1 },
        { upto: 1.04, scale: 1.05 },
        { upto: Infinity, scale: 1.1 },
      ],

      Pear: [
        { upto: 1.39, scale: 1 },
        { upto: 1.49, scale: 1.05 },
        { upto: 1.59, scale: 1.1 },
        { upto: Infinity, scale: 1.15 },
      ],
      Emerald: [
        { upto: 1.19, scale: 0.65 },
        { upto: 1.29, scale: 0.78 },
        { upto: 1.39, scale: 0.91 },
        { upto: Infinity, scale: 1 },
      ],
    };

    const shapeData = scaleMap[shape];
    if (!shapeData) return 1.0;
    const ratioData = shapeData.find((data) => Number(ratio) <= data.upto);
    return ratioData ? ratioData.scale : 1.0;
  }

  let shapeDimension = {
    Cushion: {
      height: "105px",
      top: "28px",
      width: "108px",
    },
    Radiant: {
      height: "110px",
      top: " ",
      width: "110px",
    },
    Oval: {
      height: "110px",
      top: "32px",
      width: "90px",
    },
    Heart: {
      height: "96px",
      top: "28px",
      width: "110px",
    },
    Pear: {
      height: "116px",
      top: "24px",
      width: "105px",
    },
    Emerald: {
      height: "178px",
      top: "32px",
      width: "80px",
    },
    Round: {
      height: "114px",
      top: "36px",
      width: "110px",
    },
    Marquise: {
      height: "118px",
      top: "30px",
      width: "60px",
    },
  };

  let shapeDimensionMob = {
    Cushion: {
      height: "88px",
      top: "22px",
      width: "94px",
    },
    Radiant: {
      height: "92px",
      top: "19px",
      width: "94px",
    },
    Oval: {
      height: "129px",
      top: "26px",
      width: "96px",
    },
    Heart: {
      height: "92px",
      top: "23px",
      width: "100px",
    },
    Pear: {
      height: "98px",
      top: "20px",
      width: "75px",
    },
    Emerald: {
      height: "153px",
      top: "26px",
      width: "80px",
    },
    Round: {
      height: "97px",
      top: "28px",
      width: "94px",
    },
    Marquise: {
      height: "100px",
      top: "24px",
      width: "60px",
    },
  };

  const [shapeArr, setShapeArr] = useState(shapeDimension);
  const [activeMob, setActiveMob] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 769) {
        setShapeArr(shapeDimensionMob);
        setActiveMob(true);
      }else{
        setShapeArr(shapeDimension);
        setActiveMob(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <Helmet>
        <title>FCRF - True Face-Up</title>

        <meta property="og:title" content="True Face-Up" />
        <meta property="og:description" content="" />

        {/* <meta property="og:image" content={`${clientPath}/images/diamond-${metaImgData.color}-${metaImgData.shape}.svg`} /> */}
      </Helmet>
      {loader && <Loader type={"progress"} />}
      <div className="share-face-up-container">
        <div className="share-face-up-header">
          <img src={`${clientPath}/icons/FCRF_logo.svg`} alt="shape-1" />
        </div>
        <div
          className={`share-face-up-content-wrapper ${
            reportData?.payload?.giaNumber
              ? "share-face-up-content-wrapper-gia"
              : ""
          }`}
        >
          <div className="share-face-up-content-header">
            <h6 className="">
              Face up result for this {reportData?.payload?.carat} carat
            </h6>
          </div>
          {reportData?.payload?.giaNumber ? (
            <>
              <div
                className={`true-face-up-result ${
                  reportData?.payload?.giaNumber && "true-face-up-result-gia"
                }`}
              >
                <div className="true-face-up-result-left share-faceup-result">
                  {reportData?.report?.D29 >= 2 ? (
                    <div className="visual-presence-carat">
                      <p>Visual Presence</p>
                      <h4 className="m-0">
                        <span>
                          {(reportData?.report?.C37).replace(" - ", " to ")}{" "}
                          carat
                        </span>{" "}
                      </h4>
                      <p>{`${reportData?.report?.D29?.toFixed(2)} % Larger`}</p>
                    </div>
                  ) : -2 <= reportData?.report?.D29 &&
                    reportData?.report?.D29 <= 2 ? (
                    <div className="visual-presence-carat standard-faceup">
                      <p>Visual Presence</p>
                      <h4 className="m-0">
                        {/* <span>{reportData?.payload?.carat} carat</span>{" "} */}
                        True to its weight
                      </h4>
                    </div>
                  ) : (
                    <div className="visual-presence-carat">
                      <p>Visual Presence</p>
                      <h4 className="m-0">
                        <span>
                          {(reportData?.report?.C37).replace(" - ", " to ")}{" "}
                          carat
                        </span>{" "}
                      </h4>
                      <p>{`${reportData?.report?.D29?.toFixed(2)}% Smaller`}</p>
                    </div>
                  )}

                  <p className="mt-4 share-footer-date">
                    Face-up ID#:
                    {reportData?.postId?.replace("#", "")} for GIA Report{" "}
                    {reportData?.payload?.giaNumber} |
                    {" " + formatDate(reportData?.timestamp)} | DB Updated Feb
                    20, '25
                  </p>

                  <p className="mt-4 share-footer-date-mob">
                    Face-up ID#: {reportData?.postId?.replace("#", "")} for GIA
                    Report {reportData?.payload?.giaNumber} |
                    {" " + formatDate(reportData?.timestamp)} | DB Updated Feb
                    20, '25
                  </p>
                </div>

                <div className="true-face-up-result-right">
                  <div
                    className={`faceup-frame-content true-faceup-frame-content`}
                  >
                    <div className="faceup-frame-wrapper true-faceup-frame-wrapper share-faceup-frame-wrapper">
                      <div className={"faceup-frame"}>
                        <div 
                          className="faceup-frame-images true-faceup-images"
                          style={{
                            transform: `translateY(${
                              imgScaleVal > 1.24 ? "0" : "0"
                            }) scaleY(${imgScaleVal})`,
                          }}
                        >
                          <img
                            className={`face-card-image faceup-image-first ${
                              viewOnce ? "animate" : ""
                            }`}
                            src={`/images/diamond-${
                              DiaryDiamondColors.find(
                                (elem) =>
                                  elem?.value === reportData?.payload?.color
                              )?.img ?? "none"
                            }-${
                              DiaryDiamondShapes.find(
                                (item) =>
                                  item.value === reportData?.payload?.shape
                              )?.img
                            }.svg`}
                            alt={`new`}
                            style={{
                              transform: `translate(${
                                viewOnce ? "0%" : "-100%"
                              }, ${
                                reportData?.payload?.shape === "Pear"
                                  ? "6%"
                                  : "0%"
                              })`,
                              width: `${
                                reportData?.report?.profiler_value === "1" ||
                                reportData?.report?.profiler_value === "2" ||
                                reportData?.report?.profiler_value === "3" ||
                                reportData?.report?.profiler_value === "0"
                                  ? "200px"
                                  : reportData?.report?.profiler_value === "-1"
                                  ? "190px"
                                  : reportData?.report?.profiler_value === "-2"
                                  ? "180px"
                                  : reportData?.report?.profiler_value === "-3"
                                  ? "170px"
                                  : ""
                              }`,
                            }}
                          />

                          <img
                            className={`face-card-image ${
                              viewOnce ? "animate" : ""
                            }`}
                            src={`/images/diamond-${
                              DiaryDiamondColors.find(
                                (elem) =>
                                  elem?.value === reportData?.payload?.color
                              )?.img?? "none"
                            }-${
                              DiaryDiamondShapes.find(
                                (item) =>
                                  item.value === reportData?.payload?.shape
                              )?.img
                            }.svg`}
                            alt={`new`}
                            style={{
                              transform: `translateY(${
                                reportData?.payload?.shape === "Pear"
                                  ? "6%"
                                  : "0"
                              })`,
                              width: `${
                                reportData?.report?.profiler_value === "0" ||
                                reportData?.report?.profiler_value === "-1" ||
                                reportData?.report?.profiler_value === "-2" ||
                                reportData?.report?.profiler_value === "-3"
                                  ? "200px"
                                  : reportData?.report?.profiler_value === "1"
                                  ? "190px"
                                  : reportData?.report?.profiler_value === "2"
                                  ? "180px"
                                  : reportData?.report?.profiler_value === "3"
                                  ? "170px"
                                  : ""
                              }`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="share-face-up-content">
                <div className="share-face-up-content-image">
                  <div className={"faceup-frame discover-weight-frame"}>
                    <div className="faceup-frame-weight-container">
                      <div className="faceup-frame-weight-content">
                        <p>{reportData?.payload?.length}</p>
                        <div
                          className="faceup-frame-left-arrow"
                          style={{
                            transform: `scaleY(${getScaleForImage(
                              reportData?.payload?.shape,
                              (Number(reportData?.payload?.length) || 0) /
                                (Number(reportData?.payload?.width) || 0)
                            )})`,
                          }}
                        >
                          <img
                            // src={`${clientPath}/icons/weight-left-arrow.svg`}
                            src={arrowLeft}
                            alt="weight-left-arrow"
                            style={
                              {
                                // transform: `scaleY(${getScaleForImage(
                                //   reportData?.payload?.shape,
                                //   ( Number(reportData?.payload?.length) || 0) /
                                //   ( Number(reportData?.payload?.width) || 0)
                                // )})`,
                                // height: shapeArr[reportData?.payload?.shape]?.height
                              }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`discover-weight-faceup-image`}>
                      <div
                        className={`top-image-arrow-section ${
                          ["Pear", "Oval", "Emerald"].includes(
                            reportData?.payload?.shape
                          )
                            ? "thin-arrow-section"
                            : reportData?.payload?.shape === "Marquise"
                            ? "marquise-arrow-section"
                            : ""
                        }`}
                      >
                        <div className="top-image-arrow">
                          <p>
                            {reportData?.payload?.weight ||
                              reportData?.payload?.width}
                          </p>
                          <div
                            className={` weight-top-arrow ${
                              ["Pear", "Oval", "Emerald"].includes(
                                reportData?.payload?.shape
                              )
                                ? "thin-arrow-image"
                                : reportData?.payload?.shape === "Marquise"
                                ? "marquise-arrow-image"
                                : ""
                            }`}
                          >
                            <img
                              src={arrowTop}
                              alt="5.3"
                              style={{
                                width:
                                  shapeArr[reportData?.payload?.shape]?.width,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <img
                      className="image-shadow"
                        src={`${clientPath}/images/diamond-${
                          reportData?.type === "discoverWeight"
                            ? "light-grey"
                            : DiaryDiamondColors.find(
                                (elem) =>
                                  elem?.value === reportData?.payload?.color
                              )?.img ?? "none"
                        }-${
                          DiaryDiamondShapes.find(
                            (item) => item.value === reportData?.payload?.shape
                          )?.img
                        }.svg`}
                        alt="img"
                        style={{
                          width: shapeArr[reportData?.payload?.shape]?.width,
                          transform: `scaleY(${getScaleForImage(
                            reportData?.payload?.shape,
                            (Number(reportData?.payload?.length) || 0) /
                              (Number(reportData?.payload?.width) || 0)
                          )})`,
                          height: (reportData?.payload?.shape?.toLowerCase() === "marquise" && !activeMob) ? "130px" : "",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="share-face-up-content-report-text">
                  Your carat range for a{" "}
                  <span>
                    {reportData?.payload?.shape} Shape,{" "}
                    {reportData?.payload?.length} x {reportData?.payload?.width}
                  </span>{" "}
                  is:
                </p>
                <h6>
                  {reportData?.report?.C37?.split("-")[0]} to{" "}
                  {reportData?.report?.C37?.split("-")[1]} carat{" "}
                  <sup className="discover-weight-trueFace share-weight-trueFace">
                    <InfoIcon className="info-icon" />
                    <div className="discover-weight-info">
                      <p>
                        These measurements reflect the weight of an average{" "}
                        {reportData?.report?.C37?.replace(/-/, "to")} carat,
                        fancy color {reportData?.payload?.shape?.toLowerCase()} shape diamond.
                      </p>
                    </div>
                  </sup>
                </h6>
                <p className="mt-4 share-footer-date">
                  Face-up ID#:
                  {reportData?.postId?.replace("#", "")} |{" "}
                  {" " + formatDate(reportData?.timestamp)} | DB Updated Feb 20,
                  '25
                </p>

                <p className="mt-4 share-footer-date-mob">
                  Face-up ID#: {reportData?.postId?.replace("#", "")} |
                  <br />
                  {formatDate(reportData?.timestamp)} | DB Updated Feb 20, '25
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShareTrueFacePage;

const InfoIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7"
        r="6.33333"
        stroke="#BF8F02"
        stroke-width="1.33333"
      />
      <line
        x1="6.88932"
        y1="6.22266"
        x2="6.88932"
        y2="10.8893"
        stroke="#BF8F02"
        stroke-width="1.33333"
      />
      <circle cx="7.00043" cy="3.88911" r="0.777778" fill="#BF8F02" />
    </svg>
  );
};
