import { useContext, useEffect } from "react";
import {
  DiaryCauseShape,
  DiaryDiamondShapes,
  DiaryDiamondColors,
} from "../../utils/dairyDiamondsDetail";
import FcrfContext from "../../Context";

const NewDiaryWeight = ({
  profilerInfo,
  data,
  gia_report,
  imgScaleVal,
  range_slider,
  sidebarCard,
  handleSideBarCard,
  isHide,
  weightInfo,
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
//   const [viewOnce, setViewOnce] = useState(false);


  const { results } = gia_report || {};




  const result = DiaryDiamondColors.filter(
    (elem) => data?.color === elem.value
  );
  const showColorImg = result[0]?.img;

  const resultShapeCode = DiaryCauseShape.filter(
    (elem) => data?.shape_code === elem.code
  );
  const showShapeImgCode = resultShapeCode[0]?.shape;
  const resultShape = DiaryDiamondShapes.filter(
    (elem) => showShapeImgCode === elem.value
  );
  var showShapeImg = resultShape[0]?.img;

  if (results?.data?.shape?.shape_code === "OMB") {
    if (results?.data?.shape?.shape_group === "Cushion") {
      showShapeImg = "cushion";
    } else {
      showShapeImg = resultShape[0]?.img;
    }
  }
  useEffect(() => {
  
    setPdfReportData((prev) => ({ ...prev, weightBox: weightInfo   }));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightInfo]);


  const imageSizes = [
    // {
    //   width: 26,
    //   marginLeft: -4,
    // },
    // {
    //   width: 40,
    //   marginLeft:-8
    // },
    {
      width: 60,
      marginLeft:-16
    },
    {
      width: 80,
      marginLeft:-30
    },
    {
      width: 100,
      marginLeft:-48
    },
  ]; 
  
  const imageSizes2 = [
    // {
    //   width: 26,
    //   marginLeft:-0,
    // },
    // {
    //   width: 40,
    //   marginLeft: -0
    // },
    {
      width: 60,
      marginLeft:-0
    },
    {
      width: 80,
      marginLeft:-20
    },
    {
      width: 100,
      marginLeft:-48
    },
  ];

  return (
    <>
      
          <section
           className={`diary-card ${sidebarCard?.weightBox ? "show" : "hide"}`}
      id='weightBox'
        >
          {!isHide && (
        <div className='mobile-hide-card-btn'>
          <label className='diary-check-container'>
            <input
              type='checkbox'
              id={`nav-weightBox`}
              name={`weightBox`}
              onChange={() =>
                handleSideBarCard("weightBox", sidebarCard?.weightBox)
              }
              checked={sidebarCard?.weightBox}
            />
            <div className='checkmark'></div>
          </label>
        </div>
      )}
          <div className="faceup-card-main-content">
            <div className="faceup-card-content second-faceup-card-content">
              <div className="faceup-text-wrapper second-face-text-wrapper">
                <h6>
                  {weightInfo?.B32}
                </h6>
                <div className="second-faceup-inner-text">
                  <h5>{weightInfo?.D34}</h5>
                  <p>{weightInfo?.C34}</p>
                </div>
              </div>
              <div className="second-faceup-image-container">
                <div className="second-faceup-image-box">
                  {( (range_slider?.shapeName === "Cushion" || range_slider?.shapeName === "Radiant" || range_slider?.shapeName === "Round") ? imageSizes : imageSizes2).map((item, index) => (
                    <div className={`second-faceup-image ${index===4 && 'second-faceup-image-container-mbl'}`}  style={{
                      width: `${item.width}px`,
                      height: `100px`,
                    }}>
                      
                      <img
                        src={`/images/diamond-${showColorImg}-${showShapeImg}.svg`}
                        alt={`${showColorImg}-${showShapeImg}`}
                        key={index}
                        style={{
                          width :  '100%',
                          marginLeft:`${item.marginLeft}px`,
                          marginBottom:`${-(index ) * 2 }px`
                        }}
                       
                      />
                      {index===2 &&  <span style={{
                        bottom: range_slider?.shapeName === "Oval" ?  '-16px' : '-10px',
                        left: range_slider?.shapeName === "Oval" ?  '-25px' : '-29px'

                      }} >This {data?.weight}ct</span>}
                    </div>
                  ))}
                 
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default NewDiaryWeight;
